import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import localize from '../../localize';
import { paymentFormFields } from './data';
import { CustomAlert } from '../../components/CustomAlert';
import { CommonContext } from '../../contexts/CommonContext';
import { useAsyncError } from '../../hooks/useAsyncError';
import { ProjectApiClient } from '../../api/ProjectApiClient';
import { NotificationContext } from '../../contexts/NotificationContext';
import { ArrayToIntersectionType } from '../../utils/types';

const PaymentSettingsForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  flexDirection: 'column',
  margin: '24px 0',
}));

const InputsGroup = styled('div')(() => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
  gap: '24px',
  alignItems: 'center',
  marginTop: '20px',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
    '&.purple-text-label': {
      color: '#8D83FE',
    },
    '': {
      padding: '0',
    },
  },
}));

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '24px',
}));

export const ButtonsBox = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-start',
}));

export const PaymentSettings = () => {
  const paymentFormIds = paymentFormFields.map(({ id }) => id);
  type FormData = Record<
    ArrayToIntersectionType<typeof paymentFormIds>,
    string
  >;

  const { currentProject, setCurrentProject } = useContext(CommonContext);
  const { setNotification } = useContext(NotificationContext);

  const throwError = useAsyncError();

  const isValidEdrpou = useCallback((edrpou: string) => {
    const edrpouRegex = /^[0-9]{8}$/;
    return edrpouRegex.test(edrpou);
  }, []);

  const isValidIban = useCallback((iban: string) => {
    const ibanRegex = /^UA\d{2}\d{25}$/;
    return ibanRegex.test(iban);
  }, []);

  const initialStateFormData = useMemo(() => {
    return {
      ...paymentFormIds.reduce(
        (acc, key) => ({ ...acc, [key]: currentProject[key] || '' }),
        {} as FormData
      ),
    };
  }, [currentProject, paymentFormIds]);
  const [formData, setFormData] = useState<FormData>(initialStateFormData);

  const [isFormValid, setIsFormValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { edropoy_code, iban } = formData;

  const hasFormChanged = useMemo(() => {
    return paymentFormIds.some((key) => formData[key] !== currentProject[key]);
  }, [currentProject, formData, paymentFormIds]);

  useEffect(() => {
    const validForm =
      (edropoy_code === '' || isValidEdrpou(edropoy_code)) &&
      (iban === '' || isValidIban(iban));
    setIsFormValid(validForm);
  }, [edropoy_code, iban, isValidEdrpou, isValidIban]);

  const onSubmit = () => {
    setSubmitting(true);

    ProjectApiClient.update(currentProject.id, {
      project: {
        ...formData,
      },
    })
      .then(() => {
        setNotification({
          severity: 'success',
          message: localize.general.successUpdateMessage,
        });
        setCurrentProject({ ...currentProject, ...formData });
      })
      .catch((error) => {
        throwError(error);
        setNotification({
          severity: 'error',
          message: localize.general.unexpectedError,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const resetFormData = () => {
    setFormData(
      paymentFormIds.reduce(
        (acc, key) => ({ ...acc, [key]: currentProject[key] || '' }),
        {} as FormData
      )
    );
  };

  return (
    <Grid item xs={12}>
      <CustomAlert>
        {localize.projects.edit.paymentSettings.alert.infoLine}{' '}
        {/* <Link color={theme.palette.info.main} href='#'>
          {localize.projects.edit.paymentSettings.alert.documentation}
        </Link> */}
      </CustomAlert>
      <PaymentSettingsForm
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <StyledFormControl sx={{ maxWidth: '992px', rowGap: '32px' }}>
          <InputsGroup>
            {paymentFormFields.map((field) => (
              <LabelGroup key={field.id}>
                <label htmlFor={field.id}>
                  {field.label}{' '}
                  <span className='grey-text'>
                    {localize.projects.edit.main.labels.notRequired}
                  </span>
                </label>
                <TextField
                  id={field.id}
                  value={formData[field.id]}
                  onChange={(event) =>
                    setFormData({ ...formData, [field.id]: event.target.value })
                  }
                  name={field.id}
                  placeholder={field.placeholder}
                  helperText={
                    formData[field.id] === ''
                      ? ' '
                      : field.id === 'iban'
                        ? isValidIban(formData[field.id])
                          ? ' '
                          : field.errorText
                        : isValidEdrpou(formData[field.id])
                          ? ' '
                          : field.errorText
                  }
                  error={
                    (field.id === 'iban' &&
                      !isValidIban(formData[field.id]) &&
                      formData[field.id] !== '') ||
                    (field.id === 'edropoy_code' &&
                      !isValidEdrpou(formData[field.id]) &&
                      formData[field.id] !== '')
                  }
                />
              </LabelGroup>
            ))}
          </InputsGroup>
          <ButtonsBox>
            <Button
              variant='rounded'
              disabled={submitting || !isFormValid || !hasFormChanged}
              type='submit'
              endIcon={
                submitting ? (
                  <CircularProgress size={16} color='inherit' />
                ) : null
              }
            >
              {localize.projects.edit.buttonApply}
            </Button>
            <Button
              variant='transparent'
              onClick={() => resetFormData()}
              disabled={submitting || !hasFormChanged}
            >
              {localize.projects.edit.buttonClear}
            </Button>
          </ButtonsBox>
        </StyledFormControl>
      </PaymentSettingsForm>
    </Grid>
  );
};

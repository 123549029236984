import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page/Page';
import { CommonContext } from '../../contexts/CommonContext';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import { ProjectApiClient } from '../../api/ProjectApiClient';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

type EditProjectPageProps = {};

export const EditProjectPage: FC<EditProjectPageProps> = () => {
  const [values, setValues] = useState<AnyObject>({});
  const [loading, setLoading] = useState(true);
  const { currentProject } = useContext(CommonContext);
  const navigate = useNavigate();
  const location = useLocation();

  const activeTab = useMemo(() => {
    const lastItem = structuredClone(location.pathname.split('/')).pop();
    return lastItem === 'settings' ? '' : lastItem;
  }, [location]);

  const prepareData = async (): Promise<void> => {
    return ProjectApiClient.getShow(currentProject.id).then((res) => {
      setValues({
        title: res.title,
        logo: res.logo
          ? [
              {
                name: res.logo.split('/')[res.logo.split('/').length - 1],
                url: res.logo_url,
              },
            ]
          : [],
      });
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/cp/settings/${newValue}`);
  };

  useEffect(() => {
    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, []);

  if (loading || !Object.keys(values).length) return null;

  return (
    <Page title={localize.projects.edit.title} actions={[]}>
      <Grid
        container
        spacing={2}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12} paddingTop={0}>
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label='Project settings tabs'
            >
              {Object.keys(localize.projects.edit.tabs).map((key, index) => (
                <Tab
                  key={key}
                  label={localize.projects.edit.tabs[key]?.title}
                  value={localize.projects.edit.tabs[key]?.path}
                  id={`tab-${localize.projects.edit.tabs[key]?.title}`}
                  aria-label={`tab-${localize.projects.edit.tabs[key]?.title}`}
                />
              ))}
            </Tabs>
          </Box>
          <Grid item py={2} xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default EditProjectPage;

import { ChangeEvent, FC, useState, useContext, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import {
  Alert,
  Typography,
  Box,
  List,
  Button,
  IconButton,
} from '@mui/material';

import { StaticDataRecordsApiClient } from '../../api/StaticDataRecordsApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { ProjectUploadApiClient } from '../../api/ProjectUploadApiClient';
import { useAsyncError } from '../../hooks/useAsyncError';
import { CommonContext } from '../../contexts/CommonContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import localize from '../../localize';
import JsonEditorWrapper from '../../components/JSONEditor/JSONEditor';
import ListItemForAddFiles from '../../components/ListItemForAddFiles/ListItemForAddFiles';
import Loading from '../../components/Loading/Loading';
import { uploadBlobToAws } from '../../utils/aws';

import { ReactComponent as InfoIconSvg } from '../../assets/info-circle.svg';
import { ReactComponent as DownloadIconSvg } from '../../assets/download-files.svg';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { getRandomHexSegment } from '../../utils/strings';

const DarkBox = styled(Box)(() => ({
  padding: '12px 0',
  marginBottom: '30px',
  background: '#292D32',
  color: '#fff',
  border: '1px solid #7C7C7C',
  borderRadius: '12px',
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const StaticDataRecordsApiClientPage: FC = () => {
  const { currentProject } = useContext(CommonContext);
  const { setNotification } = useContext(NotificationContext);
  const throwError = useAsyncError();
  const [data, setData] = useState<AnyObject[]>([]);
  const [newData, setNewData] = useState<AnyObject | null>(null);
  const [files, setFiles] = useState<AnyObject | null>(null);
  const [dataJSON, setDataJSON] = useState<AnyObject>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [mode, setMode] = useState<'code' | 'tree'>('code');

  const preparePageData = async (): Promise<void> => {
    const apiRows = await StaticDataRecordsApiClient.getIndex(
      currentProject.id
    ).catch((e) => {
      throwError(e);
    });
    if (apiRows && apiRows.length) {
      setData(apiRows || []);
    }
  };

  const onAddFileClick = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const newFiles: AnyObject = {};
      Array.from(fileList).forEach((file: AnyObject) => {
        const apiNumber =
          getRandomHexSegment() + `.${file.name.split('.').slice(1).join('')}`;
        file.apiNumber = apiNumber;
        newFiles[file.name.split('.').shift()] = file;
      });
      setFiles((prev) => ({
        ...prev,
        ...newFiles,
      }));
      setNewData((prev) =>
        prev
          ? {
              ...prev,
              files_data: {
                ...files,
                ...newFiles,
              },
            }
          : {
              ...data[0],
              files_data: {
                ...files,
                ...newFiles,
              },
            }
      );
    }
  };

  const deleteItem = (fileToDelete: string) => {
    if (fileToDelete && files) {
      const newFiles: AnyObject = {};
      Object.entries(files).forEach(([fileName, file]) => {
        fileName !== fileToDelete && (newFiles[fileName] = file);
      });
      setFiles(newFiles);
      setNewData((prev) =>
        prev
          ? { ...prev, files_data: { ...newFiles } }
          : { ...data[0], files_data: { ...newFiles } }
      );
    }
  };

  const changeFileKey = (name: string, newFile: AnyObject) => {
    const newFiles: AnyObject = {};
    if (files) {
      Object.entries(files).forEach(([fileName, file]) => {
        fileName === name
          ? (newFiles[newFile.name.split('.').shift()] =
              newFile.pathURL || newFile)
          : (newFiles[fileName] = file);
      });
      setFiles(newFiles);
      setNewData((prev) =>
        prev
          ? { ...prev, files_data: { ...newFiles } }
          : { ...data[0], files_data: { ...newFiles } }
      );
    }
  };

  const saveData = async (): Promise<void> => {
    if (!newData) return;

    setLoading(true);

    const newDataForSave = {
      ...data[0],
      json_data: newData.json_data,
      files_data: Object.values(files || {}).some(
        (file) => typeof file !== 'string'
      )
        ? data[0].files_data
        : files,
    };

    if (data.length === 0) {
      await StaticDataRecordsApiClient.create(
        currentProject.id,
        newDataForSave
      ).then((createdStaticData) => {
        setData([createdStaticData]);
        files &&
          Promise.all(
            Object.entries(files).map(([name, file]: [string, AnyObject]) =>
              uploadBlobToAws({
                projectId: currentProject.id,
                mainItemId: createdStaticData.id,
                additionalParams: `static_data_record_id=${createdStaticData.id}&strict_file_name=${file.apiNumber}`,
                ApiClient: ProjectUploadApiClient,
                files: [file as File],
              }).then((response) => ({ response, name }))
            )
          ).then((value) => {
            const filesFields: any = {};

            value.map(
              (res) =>
                (filesFields[res.name] = res.response.errors.some((el) => !!el)
                  ? ''
                  : res.response.success[0])
            );

            if (Object.values(filesFields).some((v: any) => v !== '')) {
              StaticDataRecordsApiClient.update(
                currentProject.id,
                {
                  ...createdStaticData,
                  files_data: { ...files, ...filesFields },
                },
                data[0].id
              ).then((res) => {
                if (value.filter((v) => v.response.errors.length).length > 0) {
                  setNotification({
                    severity: 'warning',
                    message: localize.general.warningCreateMessage,
                  });
                } else {
                  setData([res]);
                  setNotification({
                    severity: 'success',
                    message: localize.general.successCreateMessage,
                  });
                }
              });
            }
          });
      });
    } else {
      await StaticDataRecordsApiClient.update(
        currentProject.id,
        newDataForSave,
        data[0].id
      ).then((createdStaticData) => {
        setData([createdStaticData]);
        files &&
          Promise.all(
            Object.entries(files)
              .filter((file) => typeof file[1] !== 'string')
              .map(([name, file]: [string, AnyObject]) =>
                uploadBlobToAws({
                  projectId: currentProject.id,
                  mainItemId: data[0].id,
                  additionalParams: `static_data_record_id=${createdStaticData.id}&strict_file_name=${file.apiNumber}`,
                  ApiClient: ProjectUploadApiClient,
                  files: [file as File],
                }).then((response) => ({ response, name }))
              )
          ).then((value) => {
            const filesFields: any = {};

            value.map(
              (res) =>
                (filesFields[res.name] = res.response.errors.some((el) => !!el)
                  ? ''
                  : res.response.success[0])
            );

            if (Object.values(filesFields).some((v: any) => v !== '')) {
              StaticDataRecordsApiClient.update(
                currentProject.id,
                {
                  ...createdStaticData,
                  files_data: { ...files, ...filesFields },
                },
                data[0].id
              ).then((res) => {
                if (value.filter((v) => v.response.errors.length).length > 0) {
                  setNotification({
                    severity: 'warning',
                    message: localize.general.warningCreateMessage,
                  });
                } else {
                  setData([res]);
                  setNotification({
                    severity: 'success',
                    message: localize.general.successUpdateMessage,
                  });
                }
              });
            }
          });
      });
    }
    setLoading(false);
  };

  const resetChanges = () => {
    setDataJSON(data.length !== 0 ? { ...data[0].json_data } : {});
    setFiles(data.length !== 0 ? { ...data[0].files_data } : null);
    setNewData(null);
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
    setNewData(null);
  }, [currentProject]);

  useEffect(() => {
    if (!!data.length) {
      setDataJSON(data[0].json_data);
      setFiles(data[0].files_data);
    }
  }, [data]);

  if (loading) return <Loading />;

  return (
    <>
      <Alert
        severity='info'
        icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
      >
        {localize.api_integration.static_data.info_box_1}
        {/* <Link href='#api-documentation' color='#0A7AFF' ml='5px'>
          {localize.api_integration.static_data.api_documentation_link}
        </Link> */}
      </Alert>
      <Alert
        severity='info'
        icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
      >
        {localize.api_integration.static_data.info_box_2}
        {/* <Link href='#api-documentation' color='#0A7AFF' ml='5px'>
          {localize.api_integration.static_data.api_documentation_link}
        </Link> */}
      </Alert>
      <DarkBox>
        <Box
          p='0 20px 12px'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ borderBottom: '1px solid #7C7C7C' }}
        >
          <Typography color='#fff' variant='body2'>
            {localize.api_integration.static_data.files_box}
          </Typography>
          <Button
            component='label'
            role={undefined}
            tabIndex={-1}
            variant='text'
            startIcon={<DownloadIconSvg />}
            sx={{
              textTransform: 'none',
              textDecoration: 'underline',
              fontSize: '14px',
              lineHeight: '17.07px',
              fontWeight: 500,
              color: '#fff',
            }}
          >
            {localize.api_integration.static_data.download_btn}
            <VisuallyHiddenInput
              type='file'
              onChange={(event) => {
                onAddFileClick(event);
              }}
              multiple
            />
          </Button>
        </Box>
        <Box p='12px 20px'>
          <Typography color='#fff' variant='body1' component='p'>
            {'{'}
          </Typography>
          {files && !!Object.entries(files).length ? (
            <List sx={{ margin: '0 20px' }}>
              {Object.entries(files).map(([fileName, path], ind) => (
                <ListItemForAddFiles
                  key={fileName}
                  fileUrl={data[0].signed_files_data[fileName]?.url || '#'}
                  file={{ fileName, path }}
                  isLast={Object.entries(files).length - 1 === ind}
                  deleteItem={deleteItem}
                  changeFileKey={changeFileKey}
                />
              ))}
            </List>
          ) : (
            <Typography ml='20px' color='#fff' variant='body1' component='p'>
              Empty list
            </Typography>
          )}
          <Typography color='#fff' variant='body1' component='p'>
            {'}'}
          </Typography>
        </Box>
      </DarkBox>
      <Alert
        severity='info'
        icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
      >
        {localize.api_integration.static_data.info_box_3}
        {/* <Link href='#api-documentation' color='#0A7AFF' ml='5px'>
          {localize.api_integration.static_data.api_documentation_link}
        </Link> */}
      </Alert>
      <DarkBox>
        <Box
          p='0 20px 12px'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ borderBottom: '1px solid #7C7C7C' }}
        >
          <Typography color='#fff' variant='body2'>
            {localize.api_integration.static_data.json_box}
          </Typography>
          <IconButton
            sx={{ color: '#fff' }}
            onClick={() => {
              setMode((prev) => (prev === 'code' ? 'tree' : 'code'));
            }}
          >
            {mode === 'code' ? <AccountTreeIcon /> : <EditNoteIcon />}
          </IconButton>
        </Box>
        <JsonEditorWrapper
          jsonData={dataJSON}
          mode={mode}
          onChange={(json: AnyObject) => {
            setNewData((prev) =>
              prev
                ? { ...prev, json_data: { ...json } }
                : { ...data[0], json_data: { ...json } }
            );
          }}
        />
      </DarkBox>
      <Button
        variant='rounded'
        sx={{ mr: '10px' }}
        disabled={!newData}
        onClick={() => {
          saveData();
        }}
      >
        {localize.general.submit}
      </Button>
      <Button disabled={!newData} variant='transparent' onClick={resetChanges}>
        {localize.general.cancel}
      </Button>
    </>
  );
};

export default StaticDataRecordsApiClientPage;

import { FC, ReactNode, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Page from '../components/Page/Page';
import { NotificationProvider } from '../contexts/NotificationContext';
import { CommonContext } from '../contexts/CommonContext';
import Loading from '../components/Loading/Loading';
import { ProfilePage } from './profile';
import {
  UserCreatePage,
  UserEditPage,
  UserShowPage,
  UsersIndexPage,
} from './users';
import {
  ProductCreatePage,
  ProductEditPage,
  ProductIndexPage,
  ProductShowPage,
} from './products';
import { StockEditPage } from './stocks';
import ErrorBoundary from '../ErrorBoundary';
import {
  BookingCreatePage,
  BookingEditPage,
  BookingIndexPage,
  BookingShowPage,
} from './bookings';
import {
  OrderCreatePage,
  OrderEditPage,
  OrderIndexPage,
  OrderShowPage,
} from './orders';
import {
  ManufactureRequestCreatePage,
  ManufactureRequestIndexPage,
  ManufactureRequestShowPage,
} from './manufacture-requests';
import { EditProjectPage } from './edit-project';
import { TemplateEditPage } from './templates';
import {
  StaticDataRecordsApiClientPage,
  ApiKeysManagePage,
  RequestFormsApiClientPage,
  ApiKeysIndexPage,
} from './api-keys';
import {
  PromotionIndexPage,
  PromotionCreatePage,
  PromotionShowPage,
  PromotionEditPage,
} from './promotions';
import { AddonsDashboardPage } from './addons';
import ClientIndexPage from './clients/ClientIndexPage';
import { ClientShowPage } from './clients';
import ClientCreatePage from './clients/ClientCreatePage';
import ClientEditPage from './clients/ClientEditPage';
import { OnboardingPage } from './onboarding';
import { ProjectSettings } from './edit-project/ProjectSettings';
import { PaymentSettings } from './edit-project/PaymentSettings';
import { Notifications } from './profile/Notifications';
import { Support } from './profile/Support';
import { Security } from './profile/Security';
import { DropshipperProfile } from './profile/DropshipperProfile';
import { MainSettings } from './profile/MainSettings';

type MainProps = {};

export const Main: FC<MainProps> = () => {
  const { loading, currentUser } = useContext(CommonContext);

  if (loading || !currentUser?.auth0_id) return <Loading fullPage={true} />;

  if (!currentUser.onboarded) return <OnboardingPage />;

  const withErrorBoundary = (element: ReactNode): ReactNode => (
    <ErrorBoundary key={Math.random()}>{element}</ErrorBoundary>
  );

  return (
    <Page root>
      <NotificationProvider>
        <Routes>
          <Route
            path='/'
            element={withErrorBoundary(<Navigate to='/cp/products' replace />)}
          />
          <Route
            path='/integrations/api'
            key='api-keys-index'
            element={withErrorBoundary(
              <ApiKeysIndexPage key='api-keys-index' />
            )}
          >
            <Route
              index
              key='api-keys-index'
              element={withErrorBoundary(<Navigate to='static-data' />)}
            />
            <Route
              path='static-data'
              key='api-keys-static-data'
              element={withErrorBoundary(
                <StaticDataRecordsApiClientPage key='api-keys-static-data' />
              )}
            />
            <Route
              path='request-form'
              key='api-keys-request-form'
              element={withErrorBoundary(
                <RequestFormsApiClientPage key='api-keys-request-form' />
              )}
            />
            <Route
              path='manage'
              key='api-keys-manage'
              element={withErrorBoundary(
                <ApiKeysManagePage key='api-keys-manage' />
              )}
            />
          </Route>
          <Route
            path='/templates/edit/:type'
            key='template-edit'
            element={withErrorBoundary(
              <TemplateEditPage key='template-edit' />
            )}
          />
          <Route
            path='/products'
            key='products-index'
            element={withErrorBoundary(
              <ProductIndexPage key='products-index' />
            )}
          />
          <Route
            path='/products/:id'
            key='products-show'
            element={withErrorBoundary(<ProductShowPage key='products-show' />)}
          />
          <Route
            path='/products/edit/:id'
            key='products-edit'
            element={withErrorBoundary(<ProductEditPage key='products-edit' />)}
          />
          <Route
            path='/products/create'
            key='products-create'
            element={withErrorBoundary(
              <ProductCreatePage key='products-create' />
            )}
          />
          <Route
            path='/users/:userType'
            key='users-index'
            element={withErrorBoundary(<UsersIndexPage key='users-index' />)}
          />
          <Route
            path='/users/:userType/:id'
            key='users-show'
            element={withErrorBoundary(<UserShowPage key='users-show' />)}
          />
          <Route
            path='/users/:userType/edit/:id'
            key='users-edit'
            element={withErrorBoundary(<UserEditPage key='users-edit' />)}
          />
          <Route
            path='/users/:userType/create'
            key='users-create'
            element={withErrorBoundary(<UserCreatePage key='users-create' />)}
          />
          <Route
            path='/clients'
            key='clients-index'
            element={withErrorBoundary(<ClientIndexPage key='clients-index' />)}
          />
          <Route
            path='/clients/:id'
            key='clients-show'
            element={withErrorBoundary(<ClientShowPage key='clients-show' />)}
          />
          <Route
            path='/clients/edit/:id'
            key='clients-edit'
            element={withErrorBoundary(<ClientEditPage key='clients-edit' />)}
          />
          <Route
            path='/clients/create'
            key='clients-create'
            element={withErrorBoundary(
              <ClientCreatePage key='clients-create' />
            )}
          />
          <Route
            path='/stocks/edit/:id'
            key='stocks-edit'
            element={withErrorBoundary(<StockEditPage key='stocks-edit' />)}
          />
          <Route
            path='/bookings'
            key='bookings-index'
            element={withErrorBoundary(
              <BookingIndexPage key='bookings-index' />
            )}
          />
          <Route
            path='/bookings/create'
            key='bookings-create'
            element={withErrorBoundary(
              <BookingCreatePage key='bookings-create' />
            )}
          />
          <Route
            path='/bookings/:id'
            key='bookings-show'
            element={withErrorBoundary(<BookingShowPage key='bookings-show' />)}
          />
          <Route
            path='/bookings/edit/:id'
            key='bookins-edit'
            element={withErrorBoundary(<BookingEditPage key='bookins-edit' />)}
          />
          <Route
            path='/orders'
            key='orders-index'
            element={withErrorBoundary(<OrderIndexPage key='orders-index' />)}
          />
          <Route
            path='/orders/create'
            key='orders-create'
            element={withErrorBoundary(<OrderCreatePage key='orders-create' />)}
          />
          <Route
            path='/orders/:id'
            key='orders-show'
            element={withErrorBoundary(<OrderShowPage key='orders-show' />)}
          />
          <Route
            path='/orders/:id/edit/:type'
            key='orders-edit'
            element={withErrorBoundary(<OrderEditPage key='orders-edit' />)}
          />
          <Route
            path='/manufacture_requests'
            key='manufacture_requests-index'
            element={withErrorBoundary(
              <ManufactureRequestIndexPage key='manufacture_requests-index' />
            )}
          />
          <Route
            path='/manufacture_requests/create'
            key='manufacture_requests-create'
            element={withErrorBoundary(
              <ManufactureRequestCreatePage key='manufacture_requests-create' />
            )}
          />
          <Route
            path='/manufacture_requests/:id'
            key='manufacture_requests-show'
            element={withErrorBoundary(
              <ManufactureRequestShowPage key='manufacture_requests-show' />
            )}
          />
          <Route
            path='/promotions'
            key='promotions-index'
            element={withErrorBoundary(
              <PromotionIndexPage key='promotions-index' />
            )}
          />
          <Route
            path='/promotions/create'
            key='promotions-create'
            element={withErrorBoundary(
              <PromotionCreatePage key='promotions-create' />
            )}
          />
          <Route
            path='/promotions/:id'
            key='promotions-show'
            element={withErrorBoundary(
              <PromotionShowPage key='promotions-show' />
            )}
          />
          <Route
            path='/promotions/edit/:id'
            key='promotions-edit'
            element={withErrorBoundary(
              <PromotionEditPage key='promotions-edit' />
            )}
          />
          <Route
            path='/profile'
            key='profile-page'
            element={withErrorBoundary(<ProfilePage key='profile-page' />)}
          >
            <Route
              index
              element={withErrorBoundary(<MainSettings key='main-settings' />)}
            />
            <Route
              path='/profile/drop'
              element={withErrorBoundary(
                <DropshipperProfile key='dropshipper-profile' />
              )}
            />
            <Route
              path='/profile/notifications'
              element={withErrorBoundary(
                <Notifications key='profile-notifications' />
              )}
            />
            <Route
              path='/profile/security'
              element={withErrorBoundary(<Security key='profile-security' />)}
            />
            <Route
              path='/profile/support'
              element={withErrorBoundary(<Support key='profile-support' />)}
            />
          </Route>
          <Route
            path='/settings'
            key='settings-page'
            element={withErrorBoundary(<EditProjectPage key='settings-page' />)}
          >
            <Route
              index
              element={withErrorBoundary(
                <ProjectSettings key='project-settings' />
              )}
            />
            <Route
              path='/settings/payments'
              key='settings-page-tab-default'
              element={withErrorBoundary(
                <PaymentSettings key='payment-settings' />
              )}
            />
          </Route>
          <Route
            path='/addons'
            key='addons-page'
            element={withErrorBoundary(
              <AddonsDashboardPage key='addons-page' />
            )}
          />
        </Routes>
      </NotificationProvider>
    </Page>
  );
};

export default Main;

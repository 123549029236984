import { FC, useContext, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import {
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItemButton as _ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Paper,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import localize from '../../localize';
import { CommonContext } from '../../contexts/CommonContext';
import { SearchBox } from './SearchInputForSideBar';
import { useMobile } from '../../hooks/useMobile';
import ProjectMenuItems from '../ProjectMenuItems/ProjectMenuItems';
import SideBarListItem from '../SideBarListItem/SideBarListItem';
import {
  basicSideBarItems,
  innerSideBarItems,
  SideBarItem,
} from '../../utils/navigationLinks';

import { ReactComponent as LogoTextSvg } from '../../assets/logoText.svg';
import logoIcon from '../../assets/logoIcon.svg';
import { ReactComponent as LogoutIconSvg } from '../../assets/logout.svg';
import { ReactComponent as CloseMenuIconSvg } from '../../assets/menu-close.svg';
import { ReactComponent as SettingIconSvg } from '../../assets/setting.svg';

type SideBarProps = {
  variant?: 'permanent' | 'persistent' | 'temporary';
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  paddingTop: '20px',
}));

const Logo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '20px',
  flex: '1 1 auto',

  svg: {
    marginLeft: '15px',
    width: '72px',
    height: 'auto',
  },
}));

const Toogler = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingLeft: '5px',
}));

const ListItemButton = styled(_ListItemButton)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,

    '& svg path:first-of-type': {
      fill: theme.palette.primary.main,
    },
  },
})) as typeof _ListItemButton;

const SidebarBox = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 11,

  '@media (min-width: 900px)': {
    width: 280,
    background: 'none',
  },
}));

const SidebarInnerBox = styled(Box)(() => ({
  flex: 1,
  maxWidth: 280,
  backgroundColor: '#fff',
  borderRadius: '24px',
  border: '1px solid #F2F2F2',
  boxShadow: '4px 8px 32px 0px #5043CF14',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  '&.visible': {
    overflow: 'visible',
  },
}));

export const SideBar: FC<SideBarProps> = ({ variant = 'permanent' }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, isAuthenticated } = useAuth0();

  const { currentUser, currentProject } = useContext(CommonContext);

  const mobile = useMobile();

  const closeIfMobile = (): void => {
    if (mobile) setOpen(false);
  };

  const userPermissions = useMemo(
    () => Object.keys(currentUser?.user_permissions),
    [currentUser]
  );

  const filterNavLinksByUserRole = (
    array: SideBarItem[],
    userPermissions: string[]
  ): SideBarItem[] =>
    array.filter((item) =>
      // promotion only for nettie
      item.name === 'promotion' &&
      currentProject.title.toLowerCase() !== 'nettie'
        ? false
        : userPermissions.includes(item.name) &&
          Object.values(currentUser.user_permissions[item.name]).some(
            (a_p: any) => a_p.length > 0
          )
    );

  const filterUsersNavLinks = (array: SideBarItem[]): SideBarItem[] =>
    array.filter((el) =>
      el.name === 'users-clients'
        ? true
        : el.name === 'users-drop'
          ? currentUser.user_permissions['user']?.read?.includes('all') ||
            currentUser.user_permissions['user']?.read?.includes('relative')
            ? true
            : false
          : el.name === 'users-admin'
            ? currentUser.user_permissions['user']?.read?.includes('all')
            : false
    );

  const SideBarInner = () => (
    <SidebarBox>
      <SidebarInnerBox id='sidebar'>
        <DrawerHeader>
          <Logo>
            <Avatar src={logoIcon} sx={{ marginLeft: '12px' }} />
            <LogoTextSvg className='logo-text' />
          </Logo>
          {mobile && open && (
            <IconButton onClick={(e) => setOpen(false)}>
              <CloseMenuIconSvg />
            </IconButton>
          )}
        </DrawerHeader>
        <br />
        <SearchBox />
        <ProjectMenuItems />
        <br />
        <Typography variant='subtitle2' margin='0 0 8px 16px' color='#71717A'>
          {localize.sidebar.menu}
        </Typography>
        <List
          sx={{
            marginBottom: 'auto',
            padding: '0',
            width: 260,
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          {isAuthenticated &&
            basicSideBarItems.map((item, index) => (
              <SideBarListItem
                key={item.name}
                item={item}
                setSidebarOpen={setOpen}
                innerItems={
                  innerSideBarItems[item.name] && (
                    <List
                      sx={{
                        margin: '0 16px',
                        padding: '0',
                        borderRadius: '8px',
                        background: '#FAF9FF',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                      }}
                    >
                      {item.name === 'users'
                        ? filterUsersNavLinks(innerSideBarItems[item.name]).map(
                            (innerItem, index) => (
                              <SideBarListItem
                                key={innerItem.name}
                                item={innerItem}
                                setSidebarOpen={setOpen}
                                className='inner-item'
                                selectedItem={
                                  !!(
                                    innerItem.link &&
                                    location.pathname.startsWith(innerItem.link)
                                  )
                                }
                              />
                            )
                          )
                        : filterNavLinksByUserRole(
                            innerSideBarItems[item.name],
                            userPermissions
                          ).map((innerItem, index) => (
                            <SideBarListItem
                              key={innerItem.name}
                              item={innerItem}
                              setSidebarOpen={setOpen}
                              className='inner-item'
                              selectedItem={
                                !!(
                                  innerItem.link &&
                                  location.pathname.startsWith(innerItem.link)
                                )
                              }
                            />
                          ))}
                    </List>
                  )
                }
              />
            ))}
        </List>
        <Paper
          component='div'
          sx={{
            display: 'flex',
            width: 228,
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '4px 8px 32px 0px #5043CF14',
            margin: '0 auto 16px',
          }}
        >
          <List sx={{ width: '100%' }}>
            <ListItemButton
              key='user_info'
              sx={{
                minHeight: 48,
                px: 2.5,
              }}
              onClick={(e: any): void => {
                e.preventDefault();
                closeIfMobile();
                navigate(`/cp/profile`);
              }}
            >
              <ListItemAvatar
                sx={{
                  width: 40,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  mx: open ? 0 : 'auto',
                }}
              >
                <Avatar variant='rounded'>
                  {currentUser?.name?.split(' ')?.length > 1
                    ? `${currentUser?.name?.split(' ')[0][0]}${currentUser?.name?.split(' ')[1][0]}`
                    : currentUser?.name}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={currentUser?.name}
                secondary={localize.users.roles[currentUser?.role]}
                primaryTypographyProps={{ variant: 'h5', color: '#09090B' }}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  color: '#71717A',
                }}
              />
            </ListItemButton>
            <Divider />
            <ListItemButton
              key='profile_setting'
              sx={{
                minHeight: 48,
                px: 2.5,
                mb: '4px',
              }}
              onClick={(e: any): void => {
                e.preventDefault();
                closeIfMobile();
                navigate('/cp/profile');
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                  color: '',
                }}
              >
                <SettingIconSvg />
              </ListItemIcon>
              <ListItemText
                primary={localize.sidebar.settings}
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  color: '#52525B',
                }}
              />
            </ListItemButton>
            <ListItemButton
              key='logout'
              sx={{
                minHeight: 48,
                px: 2.5,
              }}
              onClick={(e: any): void => {
                e.preventDefault();
                closeIfMobile();
                logout({ returnTo: window.origin });
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                  color: '',
                }}
              >
                <LogoutIconSvg />
              </ListItemIcon>
              <ListItemText
                primary={localize.sidebar.logOut}
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  color: '#52525B',
                }}
              />
            </ListItemButton>
          </List>
        </Paper>
      </SidebarInnerBox>
    </SidebarBox>
  );

  return (
    <>
      {mobile ? (
        <>
          <MuiDrawer
            variant='temporary'
            open={open}
            anchor='left'
            onClose={(e) => setOpen(false)}
          >
            <SideBarInner />
          </MuiDrawer>
          <DrawerHeader
            sx={{
              position: 'absolute',
              width: '100%',
              display: 'flex',
              background: '#fff',
              padding: '10px 8px',
              zIndex: 10,
            }}
          >
            <Logo>
              <Avatar src={logoIcon} sx={{ marginLeft: '12px' }} />
              <LogoTextSvg className='logo-text' />
            </Logo>
            <Toogler>
              <IconButton onClick={(e) => setOpen(true)}>
                <MenuIcon />
              </IconButton>
            </Toogler>
          </DrawerHeader>
        </>
      ) : (
        <SideBarInner />
      )}
    </>
  );
};

export default SideBar;

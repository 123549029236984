import { FC, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import localize from '../../localize';

type EmptyContentProps = {
  fullPage?: boolean;
  title?: string;
  subtitle?: string;
  withoutImg?: boolean;
};

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  justifySelf: 'center',
  flexDirection: 'column',
  minWidth: '100%',
  minHeight: '80%',
}));

const FullPageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '100vh',
  minWidth: '100vw',
}));

export const EmptyContent: FC<EmptyContentProps> = ({
  title,
  subtitle,
  withoutImg = false,
  fullPage = false,
}) => {
  const Container = useMemo(
    () => (fullPage ? FullPageContainer : InnerContainer),
    [fullPage]
  );

  return (
    <Container>
      {!withoutImg && (
        <Box
          width='80px'
          height='80px'
          mb='20px'
          sx={{
            '&>img': {
              display: 'block',
              objectPosition: 'center',
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            },
          }}
        >
          <img src='/empty-box.png' alt='empty box' />
        </Box>
      )}
      <Typography variant='h5' color='#464646'>
        {title || localize.general.emptyPage}
      </Typography>
      {subtitle && <Typography variant='h4'>{subtitle}</Typography>}
    </Container>
  );
};

export default EmptyContent;

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../../contexts/CommonContext';
import { useOnboardingMultiStep } from '../../../contexts/OnboardingContext';
import { useAsyncError } from '../../../hooks/useAsyncError';
import localize from '../../../localize';
import logoPlaceholder from '../../../assets/onboarding/logo-placeholder.svg';
import { StyledInput } from '../../../components/StyledInput';
import {
  ButtonsBox,
  MainDescription,
  MainTitle,
  OnboaringPageContainer,
} from '../OnboardingPage';
import ShowStepNumbers from '../ShowStepNumbers';
import { ProjectApiClient } from '../../../api/ProjectApiClient';
import { ProjectUploadApiClient } from '../../../api/ProjectUploadApiClient';
import { uploadBlobToAws } from '../../../utils/aws';

const PageInnerContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '751px',
  width: '100%',
  margin: '0 auto',
  minHeight: 'calc(100vh + 100px)',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HeadingBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  padding: '0 32px',
}));

const UserForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '54px',
}));

const MainFieldsBox = styled('div')(() => ({
  marginTop: '20px',
}));

const InputsGroup = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'center',
  marginTop: '20px',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
    '&.purple-text-label': {
      color: '#8D83FE',
    },
    '': {
      padding: '0',
    },
  },
}));

const FileInputLabel = styled('label')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'normal',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
}));

const LogoPlaceholder = styled('img')(() => ({
  maxWidth: '67px',
  height: '67px',
  borderRadius: '4px',
  objectFit: 'cover',
  objectPosition: 'center',
}));

const LogoImage = styled('img')(() => ({
  width: '67px',
  height: '67px',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '4px',
}));

type FormData = {
  title: string;
  category: string;
  description: string;
  image: {
    path: string | null;
    files: File[];
  };
};

type CategoryData = {
  label: string;
  description: string;
};

export const ManufacturerProjectInfo = () => {
  const [formData, setFormData] = useState<FormData>({
    title: '',
    category: '',
    description: '',
    image: {
      path: null,
      files: [],
    },
  });

  const categoryData: CategoryData[] = localize.onboarding.categoryData;

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          image: {
            path: reader.result as string,
            files: [file],
          },
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const [isFormValid, setIsFormValid] = useState(false);

  const { step, getTotalSteps, nextStep, prevStep } = useOnboardingMultiStep();
  const { currentProject, fetchMainData } = useContext(CommonContext);
  const throwError = useAsyncError();

  useEffect(() => {
    if (currentProject?.id)
      setFormData({
        title: currentProject.title,
        category: currentProject.category,
        description: currentProject.description,
        image: {
          path: currentProject.logo_url,
          files: [],
        },
      });
  }, [currentProject]);

  useEffect(() => {
    const isValidForm = !!formData.title && !!formData.category;

    setIsFormValid(isValidForm);
  }, [formData.category, formData.title]);

  const onSubmit = async () => {
    try {
      const newProject = await (currentProject?.id
        ? await ProjectApiClient.update(currentProject.id, {
            project: {
              title: formData.title,
              category: formData.category,
              description: !!formData.description
                ? formData.description
                : undefined,
            },
          })
        : ProjectApiClient.create({
            project: {
              title: formData.title,
              category: formData.category,
              description: !!formData.description
                ? formData.description
                : undefined,
            },
          }));
      if (!formData.image?.files.length) nextStep();

      const uploadedLogo = await uploadBlobToAws({
        projectId: newProject.id,
        ApiClient: ProjectUploadApiClient,
        contentType: formData.image.files[0].type,
        files: formData.image.files,
      });

      await ProjectApiClient.update(newProject.id, {
        project: {
          logo: uploadedLogo.success[0],
        },
      });

      localStorage.setItem('currentProjectId', newProject.id);
      fetchMainData();
      nextStep();
    } catch (e) {
      throwError(e);
    }
  };

  return (
    <OnboaringPageContainer>
      <PageInnerContainer>
        <ShowStepNumbers currentStep={step} totalSteps={getTotalSteps()} />
        <HeadingBox>
          <MainTitle>{localize.onboarding.companyInfoTitle}</MainTitle>
          <MainDescription sx={{ margin: '0' }}>
            {localize.onboarding.companyInfoDescription}
          </MainDescription>
          <UserForm>
            <FormControl sx={{ width: '100%' }}>
              <MainFieldsBox>
                <InputsGroup>
                  <LabelGroup>
                    <FileInputLabel htmlFor='logo'>
                      <span>{localize.onboarding.formLabels.logo}</span>

                      <Box display='flex' alignItems='flex-start'>
                        <Button
                          variant='outlined'
                          component='div'
                          sx={{
                            borderRadius: '4px',
                            backgroundColor: '#f6f6fe',
                            borderColor: '#f6f6fe',
                            width: '67px',
                            height: '67px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                              backgroundColor: '#e8e8ff',
                              borderColor: '#e8e8ff',
                            },
                          }}
                        >
                          {formData.image.path ? (
                            <LogoImage
                              width={67}
                              height={67}
                              src={formData.image.path}
                              alt='logo'
                            />
                          ) : (
                            <LogoPlaceholder
                              alt='logo placeholder'
                              src={logoPlaceholder}
                            />
                          )}
                          <input
                            type='file'
                            accept='image/*'
                            hidden
                            id='logo'
                            onChange={handleImageChange}
                          />
                        </Button>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            gap: '8px',
                            marginLeft: '16px',
                          }}
                        >
                          <Link
                            className='purple-text-label'
                            sx={{
                              textTransform: 'none',
                              padding: 0,
                              fontSize: '12px',
                              cursor: 'pointer',
                              color: '#777',
                              fontFamily: 'Montserrat, sans-serif',
                              fontWeight: '400',
                              textDecoration: 'underline',
                            }}
                            component='label'
                            htmlFor='logo'
                          >
                            Виберіть фото
                          </Link>
                          <Typography
                            variant='body1'
                            sx={{
                              color: '#777',
                              fontWeight: '400',
                              maxWidth: '300px',
                              fontFamily: '"Montserrat", sans-serif',
                            }}
                          >
                            Оберіть зображення, бажано квадратне з розширенням
                            200x200px
                          </Typography>
                        </Box>
                      </Box>
                    </FileInputLabel>
                  </LabelGroup>
                  <LabelGroup>
                    <label htmlFor='title'>
                      Назва проєкту<span className='red-star'>*</span>
                    </label>
                    <StyledInput
                      id='title'
                      value={formData.title}
                      placeholder='Введіть назву проєкту'
                      helperText={
                        formData.title === '' ? "Поле обов'язкове" : ' '
                      }
                      name='title'
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          title: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                  <LabelGroup>
                    <FormLabel component={'label'}>
                      {localize.onboarding.formLabels.category}
                      <span className='red-star'>*</span>
                    </FormLabel>
                    <RadioGroup
                      value={formData.category}
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                      }}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          category: event.target.value,
                        })
                      }
                    >
                      {categoryData.map((item, index) => (
                        <Tooltip key={index} title={item.description} arrow>
                          <FormControlLabel
                            value={item.label}
                            control={<Radio />}
                            label={item.label}
                          />
                        </Tooltip>
                      ))}
                    </RadioGroup>
                  </LabelGroup>
                  <LabelGroup>
                    <label htmlFor='name'>
                      {localize.onboarding.formLabels.description}{' '}
                      <span className='grey-text'>
                        {localize.onboarding.formLabels.notRequiredButPreferred}
                      </span>
                    </label>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: '12px',
                        color: '#777',
                        fontWeight: '400',
                        fontFamily: '"Montserrat", sans-serif',
                      }}
                    >
                      {localize.onboarding.catalogDescriptionSubText}
                    </Typography>
                    <StyledInput
                      id='name'
                      value={formData.description}
                      multiline
                      rows={3}
                      placeholder='Введіть текст'
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          description: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                </InputsGroup>
              </MainFieldsBox>
            </FormControl>
          </UserForm>
        </HeadingBox>

        <ButtonsBox>
          <Button
            variant='rounded'
            onClick={onSubmit}
            disabled={!isFormValid}
            sx={{
              padding: '14px 44px',
            }}
          >
            {localize.onboarding.indexPage.nextButton}
          </Button>
          <Button variant='transparent' onClick={prevStep}>
            {localize.onboarding.indexPage.prevButton}
          </Button>
        </ButtonsBox>
      </PageInnerContainer>
    </OnboaringPageContainer>
  );
};

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { matchIsValidTel } from 'mui-tel-input';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserApiClient } from '../../../api/UserApiClient';
import { StyledInput, StyledTelInput } from '../../../components/StyledInput';
import { CommonContext } from '../../../contexts/CommonContext';
import { useOnboardingMultiStep } from '../../../contexts/OnboardingContext';
import { useAsyncError } from '../../../hooks/useAsyncError';
import localize from '../../../localize';
import {
  ButtonsBox,
  MainDescription,
  MainTitle,
  OnboaringPageContainer,
} from '../OnboardingPage';

import ShowStepNumbers from '../ShowStepNumbers';

const PageInnerContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '751px',
  width: '100%',
  margin: '0 auto',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HeadingBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  padding: '0 32px',
}));

const UserForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '54px',
}));

const MainFieldsBox = styled('div')(() => ({
  marginTop: '20px',
}));

const InputsGroup = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '24px',
  rowGap: '12px',
  alignItems: 'center',
  marginTop: '20px',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
  },
}));

type LegalType = 'ФОП' | 'Фізична особа';

type FormData = {
  name: string;
  surname: string;
  phoneNumber: string;
  legalName: string;
  edrpou: string;
  iban: string;
};

export const DropshipperMainInfo = () => {
  const isValidEdrpou = useCallback((edrpou: string) => {
    const edrpouRegex = /^[0-9]{8}$/;
    return edrpouRegex.test(edrpou);
  }, []);

  const isValidIban = useCallback((iban: string) => {
    const ibanRegex = /^UA\d{2}\d{25}$/;
    return ibanRegex.test(iban);
  }, []);

  const [legalType, setLegalType] = useState<LegalType>('ФОП');
  const [isFormValid, setIsFormValid] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    surname: '',
    phoneNumber: '',
    legalName: '',
    edrpou: '',
    iban: '',
  });

  const { step, getTotalSteps, nextStep, prevStep } = useOnboardingMultiStep();
  const { currentUser, setCurrentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const theme = useTheme();

  useEffect(() => {
    if (currentUser?.auth0_id) {
      setLegalType(currentUser?.drop_legal_type || 'ФОП');
      setFormData({
        name: `${currentUser?.name?.split(' ')?.[0]}`,
        surname: `${currentUser?.name?.split(' ')?.[1]}`,
        phoneNumber: currentUser?.phone_number || '',
        legalName: currentUser?.drop_legal_name,
        edrpou: currentUser?.edropoy,
        iban: currentUser?.iban,
      });
    }
  }, [currentUser]);

  useEffect(() => {
    setPhoneIsValid(matchIsValidTel(formData.phoneNumber));
  }, [formData.phoneNumber]);

  const legalTypeData = useMemo(() => {
    return legalType === 'ФОП'
      ? { ...formData, legalType: 'ФОП' }
      : { ...formData, legalType: 'Фізична особа' };
  }, [legalType, formData]);

  useEffect(() => {
    const isValidForm =
      !!legalTypeData.name &&
      !!legalTypeData.surname &&
      !!phoneIsValid &&
      (!!legalTypeData.edrpou ? isValidEdrpou(legalTypeData.edrpou) : true) &&
      (!!legalTypeData.iban ? isValidIban(legalTypeData.iban) : true);
    setIsFormValid(isValidForm);
  }, [phoneIsValid, isValidEdrpou, isValidIban, legalTypeData]);

  const onSubmit = async () => {
    try {
      const updatedUser = await UserApiClient.updateSelf(
        {
          user: {
            name: `${legalTypeData.name} ${legalTypeData.surname}`,
            phone_number: legalTypeData.phoneNumber,
            drop_legal_type: legalTypeData.legalType,
            drop_legal_name:
              legalTypeData.legalType === 'ФОП' ? legalTypeData.legalName : '',
            edropoy: !!legalTypeData.edrpou ? legalTypeData.edrpou : undefined,
            iban: !!legalTypeData.iban ? legalTypeData.iban : undefined,
          },
        },
        currentUser.auth0_id
      );

      setCurrentUser({ ...currentUser, updatedUser });

      nextStep();
    } catch (e) {
      throwError(e);
    }
  };
  const onLegalTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegalType((event.target as HTMLInputElement).value as LegalType);
  };

  return (
    <OnboaringPageContainer>
      <PageInnerContainer>
        <ShowStepNumbers currentStep={step} totalSteps={getTotalSteps()} />
        <HeadingBox>
          <MainTitle>{localize.onboarding.companyInfoTitle}</MainTitle>
          <MainDescription sx={{ margin: '0' }}>
            {localize.onboarding.companyInfoDescription}
          </MainDescription>
          <UserForm>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                color='primary'
                aria-labelledby='legal-type-group-label'
                value={legalType}
                onChange={onLegalTypeChange}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '56px',
                  width: '100%',
                  justifyContent: 'center',
                }}
                name='legal-type-radio-buttons-group'
              >
                <FormControlLabel
                  value='ФОП'
                  control={<Radio color='info' />}
                  label='ФОП'
                />
                <FormControlLabel
                  value='Фізична особа'
                  control={<Radio color='info' />}
                  label='Фізична особа'
                />
              </RadioGroup>

              <MainFieldsBox>
                <InputsGroup>
                  <LabelGroup>
                    <label htmlFor='name'>
                      {localize.onboarding.formLabels.name}
                      <span className='red-star'>*</span>
                    </label>
                    <StyledInput
                      id='name'
                      value={formData.name}
                      placeholder='Введіть Ім`я'
                      helperText={
                        formData.name === '' ? "Поле обов'язкове" : ' '
                      }
                      name='name'
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          name: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                  <LabelGroup>
                    <label htmlFor='surname'>
                      {localize.onboarding.formLabels.surname}
                      <span className='red-star'>*</span>
                    </label>
                    <StyledInput
                      id='surname'
                      value={formData.surname}
                      placeholder='Введіть Прізвище'
                      name='surname'
                      helperText={
                        formData.surname === '' ? "Поле обов'язкове" : ' '
                      }
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          surname: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                  <LabelGroup>
                    <label htmlFor='phone'>
                      {localize.onboarding.formLabels.phoneNumber}
                    </label>
                    <StyledTelInput
                      id='phone'
                      defaultCountry={'UA'}
                      onlyCountries={['UA']}
                      required
                      forceCallingCode
                      langOfCountryName={'ua'}
                      InputProps={{ sx: { color: '#333' } }}
                      value={formData.phoneNumber}
                      error={!phoneIsValid && formData.phoneNumber !== ''}
                      helperText={
                        !phoneIsValid
                          ? formData.phoneNumber === ''
                            ? 'Поле обов`язкове'
                            : 'Невірний формат'
                          : ' '
                      }
                      name='phone'
                      onChange={(value) => {
                        setFormData({ ...formData, phoneNumber: value });
                        setPhoneIsValid(matchIsValidTel(formData.phoneNumber));
                      }}
                    />
                  </LabelGroup>
                  {legalType === 'ФОП' ? (
                    <LabelGroup>
                      <label htmlFor='legalName'>
                        {localize.onboarding.formLabels.fopLegalName}
                      </label>
                      <StyledInput
                        id='legalName'
                        value={formData.legalName}
                        placeholder='Введіть Назву ФОП'
                        helperText={' '}
                        name='legalName'
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            legalName: event.target.value,
                          })
                        }
                      />
                    </LabelGroup>
                  ) : (
                    <LabelGroup></LabelGroup>
                  )}
                  <LabelGroup>
                    <label htmlFor='edrpou'>
                      {localize.onboarding.formLabels.edrpou}{' '}
                      <span className='grey-text'>
                        {localize.onboarding.formLabels.notRequired}
                      </span>
                    </label>
                    <StyledInput
                      id='edrpou'
                      value={formData.edrpou}
                      name='edrpou'
                      placeholder='Введіть ЄДРПОУ'
                      error={
                        !isValidEdrpou(formData.edrpou) &&
                        formData.edrpou !== ''
                      }
                      helperText={
                        formData.edrpou === ''
                          ? ' '
                          : isValidEdrpou(formData.edrpou)
                            ? ' '
                            : 'ЄДРПОУ має містити 8 цифр'
                      }
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          edrpou: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                  <LabelGroup>
                    <label htmlFor='iban'>
                      {localize.onboarding.formLabels.iban}
                      <span className='grey-text'>
                        {localize.onboarding.formLabels.notRequired}
                      </span>
                    </label>
                    <StyledInput
                      id='iban'
                      value={formData.iban}
                      placeholder='Рахунок складається з 27 цифр'
                      InputProps={{
                        sx: {
                          fontSize: '16px',
                          '&::placeholder': {
                            opacity: '1',
                            fontSize: '16px',
                          },
                        },
                      }}
                      helperText={
                        formData.iban === ''
                          ? ' '
                          : isValidIban(formData.iban)
                            ? ' '
                            : 'Має починатися з UA та містити 27 цифр'
                      }
                      error={
                        !isValidIban(formData.iban) && formData.iban !== ''
                      }
                      name='iban'
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          iban: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                </InputsGroup>
                <Typography
                  variant='body1'
                  sx={{ fontFamily: '"Montserrat", sans-serif' }}
                  color={theme.palette.info.main}
                >
                  {localize.onboarding.formBottomText}
                </Typography>
              </MainFieldsBox>
            </FormControl>
          </UserForm>
        </HeadingBox>

        <ButtonsBox>
          <Button
            variant='rounded'
            onClick={onSubmit}
            disabled={!isFormValid}
            sx={{
              padding: '14px 44px',
            }}
          >
            {localize.onboarding.indexPage.nextButton}
          </Button>
          <Button variant='transparent' onClick={prevStep}>
            {localize.onboarding.indexPage.prevButton}
          </Button>
        </ButtonsBox>
      </PageInnerContainer>
    </OnboaringPageContainer>
  );
};

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Page from '../../components/Page/Page';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { Order, OrderApiClient } from '../../api/OrderApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import { Chip, Link, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useFilter } from '../../hooks/useFilter';
import DataLine from '../../components/DataLine/DataLine';
import CircularDeterminate from '../../components/CircularDeterminate/CircularDeterminate';
import SearchBar from '../../components/SearchBar/SearchBar';
import Filter from '../../components/Filter/Filter';
import { useLocation, useNavigate } from 'react-router-dom';
// import { GenerateOrdersPdfButton } from '../../components/GenerateOrdersPdfButton/GenerateOrdersPdfButton';

const InnerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
  },
}));

const FilterButton = styled(Chip)(({ theme }) => ({
  marginRight: '20px',
  boxShadow:
    'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
  border: 0,

  [theme.breakpoints.down('sm')]: {
    marginRight: '10px',
    marginTop: '10px',
  },
}));

const Heading = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',

  '&>div': {
    marginLeft: '30px',
  },

  a: {
    marginLeft: '10px',
  },
}));

export const defaultMainColumns = [
  {
    field: 'id',
    align: 'left',
    headerName: localize.orders.columns.id,
    width: '130px',
  },
  {
    field: 'user_name',
    align: 'left',
    headerName: localize.orders.columns.user_name,
  },
  { field: 'sum', headerName: localize.orders.columns.sum },
  {
    field: 'last_status',
    headerName: localize.orders.columns.last_status,
    valueGetter: (row: any) => localize.order_statuses.values[row.last_status],
  },
  {
    field: 'sent_to_manager_at',
    headerName: localize.orders.columns.sent_to_manager_at,
    valueGetter: (row: any) =>
      row.sent_to_manager_at &&
      new Date(row.sent_to_manager_at).toLocaleDateString(),
  },
  {
    field: 'updated_at',
    headerName: localize.orders.columns.updated_at,
    valueGetter: (row: any) =>
      row.updated_at && new Date(row.updated_at).toLocaleDateString(),
  },
];

const defaultCollapsedColumns = [
  {
    field: 'product_title',
    align: 'left',
    headerName: 'Продукт',
    valueGetter: (row: any) =>
      [
        row.product?.additional_fields?.secondaryid,
        row.product?.additional_fields?.n_collection,
        row.product?.title,
      ]
        .filter((f) => !!f)
        .join(' - '),
  },
  {
    field: 'product_modification_title',
    align: 'left',
    headerName: 'Модифікація',
  },
  { field: 'count', headerName: 'Кількість' },
  { field: 'fixed_price', headerName: 'Ціна (одиниці)' },
];

export const statusesToFilterBy = [
  'Pending',
  'Complete',
  'Canceled',
  'Blocked',
];

export const OrderIndexPage: FC = () => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const location = useLocation();
  const [activeStatusFilter, setActiveStatusFilter] = useState('All');
  const [openFilter, setOpenFilter] = useState(false);
  const {
    results: rowsToShow,
    resetFilter,
    mainItems,
    subItems,
    setFilterItems,
    anyFiltersApplied,
    setInitialValue,
  } = useFilter(rows, 'orders', 'order_items');
  const [tableKey, setTableKey] = useState(322);
  const [items, setItems] = useState<Order[]>();
  const rowsToDisplay = useMemo(
    () =>
      activeStatusFilter === 'All'
        ? rowsToShow
        : rowsToShow.filter(
            (row: any) => row.last_status === activeStatusFilter
          ),
    [rowsToShow, activeStatusFilter]
  );
  const totalSum = useMemo(
    () =>
      rows
        .map((row: any) => parseFloat(row.sum))
        .reduce((a: number, b: number) => a + b, 0),
    [rows]
  );
  const totalSumComplete = useMemo(
    () =>
      rows
        .filter((row: any) => ['Complete'].includes(row.last_status))
        .map((row: any) => parseFloat(row.sum))
        .reduce((a: number, b: number) => a + b, 0),
    [rows]
  );
  const totalSumCanceled = useMemo(
    () =>
      rows
        .filter((row: any) => ['Canceled'].includes(row.last_status))
        .map((row: any) => parseFloat(row.sum))
        .reduce((a: number, b: number) => a + b, 0),
    [rows]
  );
  const totalSumPending = useMemo(
    () =>
      rows
        .filter((row: any) => ['Pending'].includes(row.last_status))
        .map((row: any) => parseFloat(row.sum))
        .reduce((a: number, b: number) => a + b, 0),
    [rows]
  );

  const numberToCurency = (num: number) =>
    num.toLocaleString('uk-UA', {
      style: 'currency',
      currency: 'UAH',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  const pageActions = useMemo(() => {
    if (!items) return [];

    const addAction = {
      title: localize.orders.index.addButton,
      link: `/cp/orders/create`,
      startIcon: <AddIcon sx={{ transform: 'scale(1.4)' }} />,
    };

    return [
      // { Component: <GenerateOrdersPdfButton orders={items} /> },
      addAction,
    ];
  }, [items]);

  const preparePageData = async (): Promise<void> => {
    const apiRows = await OrderApiClient.getIndex(currentProject.id);
    setItems(apiRows);
    setRows(apiRows);
  };

  const onFilterItemClick = (status: string) => {
    setActiveStatusFilter(status);
    navigate(`/cp/orders?status=${status}`, { replace: true });
  };

  useEffect(() => {
    setActiveStatusFilter(
      new URLSearchParams(location.search).get('status') || 'All'
    );
    setTableKey(Math.random() * 100);
  }, [rowsToShow]);

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));

    return () => setOpenFilter(false);
  }, [currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page
      title={
        <Heading>
          <Typography variant='h2'>{localize.orders.index.title}</Typography>
          {anyFiltersApplied ? (
            <div>
              знайдено {rowsToDisplay.length}
              <Link
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  resetFilter();
                }}
              >
                скинути фільтри
              </Link>
            </div>
          ) : null}
        </Heading>
      }
      actions={pageActions}
    >
      <InnerContainer>
        <DataLine
          items={[
            {
              icon: (
                <CircularDeterminate
                  value={(totalSumPending / totalSum) * 100}
                  color='secondary'
                />
              ),
              title: localize.orders.index.dataLineTotalPending,
              value: numberToCurency(totalSumPending),
            },
            {
              icon: (
                <CircularDeterminate
                  value={(totalSumCanceled / totalSum) * 100}
                  color='warning'
                />
              ),
              title: localize.orders.index.dataLineTotalCanceled,
              value: numberToCurency(totalSumCanceled),
            },
            {
              icon: (
                <CircularDeterminate
                  value={(totalSumComplete / totalSum) * 100}
                  color='success'
                />
              ),
              title: localize.orders.index.dataLineTotalComplete,
              value: numberToCurency(totalSumComplete),
            },
          ]}
        />
        <br />
        <br />
        <SearchBar
          onSearch={(smth) => setInitialValue(smth)}
          onFilterButtonClick={() => setOpenFilter((prev) => !prev)}
          anyFiltersApplied={anyFiltersApplied}
        />
        <br />
        <br />
        {openFilter && (
          <Filter
            mainItems={mainItems}
            subItems={subItems}
            anyFiltersApplied={anyFiltersApplied}
            resetFilter={resetFilter}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            applyFilter={setFilterItems}
          />
        )}

        <FilterContainer>
          {['All', 'Draft', ...statusesToFilterBy].map((el) => (
            <FilterButton
              key={el}
              label={[
                localize.order_statuses.values[el],
                el === 'All'
                  ? rowsToShow.length !== rows.length
                    ? `${rowsToShow.length}/${rows.length}`
                    : rows.length
                  : rowsToShow.length !== rows.length
                    ? `${rowsToShow.filter((item: any) => item.last_status === el).length}/${rows.filter((item: any) => item.last_status === el).length}`
                    : rows.filter((item: any) => item.last_status === el)
                        .length,
              ].join(' ')}
              variant='filled'
              color={activeStatusFilter === el ? 'primary' : 'default'}
              disabled={activeStatusFilter === el}
              onClick={() => onFilterItemClick(el)}
            />
          ))}
        </FilterContainer>
        <br />
        {!!rowsToDisplay.length ? (
          <BasicTable
            rows={rowsToDisplay}
            key={tableKey}
            columns={defaultMainColumns}
            collapsedContentColumns={{ order_items: defaultCollapsedColumns }}
            onRowClick={(row: AnyObject) => navigate(`/cp/orders/${row.id}`)}
            onColapsedRowClick={(row: AnyObject) =>
              navigate(`/cp/orders/${row.order_id}`)
            }
          />
        ) : (
          <EmptyContent />
        )}
      </InnerContainer>
    </Page>
  );
};

export default OrderIndexPage;

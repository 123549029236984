import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { NotificationContext } from '../../contexts/NotificationContext';
import { CommonContext } from '../../contexts/CommonContext';
import { preparedRequestBody } from '../../utils/form';
import { BookingApiClient } from '../../api/BookingApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import Page from '../../components/Page/Page';
import Loading from '../../components/Loading/Loading';
import BookingsForm from '../../components/BookingsForm/BookingsForm';

export const BookingEditPage: FC = () => {
  const { id } = useParams();
  const [values, setValues] = useState<AnyObject>({});
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject } = useContext(CommonContext);
  const navigate = useNavigate();

  const prepareData = async (): Promise<void> => {
    if (id) {
      const res = await BookingApiClient.getShow(currentProject.id, id);

      setValues(res);
    }
  };

  const onSubmit = ({
    mainItem,
    mainFields,
    basicMainLabels,
  }: {
    mainItem: AnyObject;
    basicMainLabels: AnyObject;
    mainFields: AnyObject[];
  }) => {
    if (!id) return;
    setSubmitting(true);

    BookingApiClient.update(
      currentProject.id,
      {
        booking: preparedRequestBody({
          mainItem,
          mainFields,
          basicMainLabels,
        }),
      },
      id
    ).then(() => {
      setNotification({
        severity: 'success',
        message: localize.general.successUpdateMessage,
      });
      setSubmitting(false);
      navigate(`/cp/bookings/${id}`);
    });
  };

  useEffect(() => {
    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, [currentProject]);

  if (loading || !Object.keys(values).length)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.bookings.edit.title} actions={[]}>
      <BookingsForm
        values={values}
        onSubmit={onSubmit}
        submitting={submitting}
      />
    </Page>
  );
};

export default BookingEditPage;

import { CssBaseline, styled, Typography } from '@mui/material';
import React from 'react';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { ConsultSection } from './components/pricing/ConsultSection';
import { FAQSection } from './components/pricing/FAQSection';
import { PlanTextSection } from './components/pricing/PlanTextSection';
import { Tarrifs } from './components/pricing/Tarrifs';
import { RootLandingWrapper } from './Landing';
export const MainTitle = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '29px',
  fontFamily: '"Montserrat", sans-serif',
  textAlign: 'center',
  marginBottom: '16px',
}));

export const MainText = styled(Typography)(() => ({
  fontSize: '16px',
  display: 'block',
  fontWeight: 400,
  fontFamily: '"Montserrat", sans-serif',
  marginBottom: '10px',
}));

export const ContainerInner = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'space-between',
  margin: '0 auto',
  alignItems: 'center',
  padding: '0 20px',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '700px',
}));

export const ColorSection = styled('section')(({ theme }) => ({
  backgroundColor: '#AFABEF',
  padding: '56px 0 34px 0',
  position: 'relative',
  color: '#fff',
}));

export const Price = () => {
  return (
    <RootLandingWrapper>
      <CssBaseline />
      <Header />
      <Tarrifs />
      <PlanTextSection />
      <FAQSection />
      <ConsultSection />
      <Footer />
    </RootLandingWrapper>
  );
};

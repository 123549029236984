import { FC, useContext, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { CommonContext } from '../../contexts/CommonContext';
import { BaseApiClient } from '../../api/BaseApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import FormField from '../FormField/FormField';
import Loading from '../Loading/Loading';
import FileUpload from '../FileUpload/FileUpload';
import localize from '../../localize';

import { ReactComponent as InfoIconSvg } from '../../assets/info-circle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-item.svg';
import { NavLink } from 'react-router-dom';

const Container = styled('form')(({ theme }) => ({
  width: '100%',
}));

const FieldsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  background: '#FFFFFF',

  '&>div': {
    width: '33%',
    minWidth: '200px',
  },
}));

const SubItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',

  '& + &': {
    paddingTop: '20px',
  },
}));

const SubItemInnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  flex: '1 1 auto',
  background: '#fff',
  borderRadius: '10px',
  padding: '30px',
  border: '1px solid #DFDDF9',

  '&>div': {
    width: '33%',
    minWidth: '200px',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '20px',
  padding: '10px 50px 10px 10px',
  gap: '20px',
}));

type ProductFormProps = {
  mainFields: AnyObject[];
  defaultMainValues?: AnyObject;
  defaultSubItemValues?: AnyObject[];
  subItemFields?: AnyObject[];
  submitting?: boolean;
  addModificationText?: string;
  onSubmit(mainItem: AnyObject, subItems: AnyObject[]): void;
};

export const ProductForm: FC<ProductFormProps> = ({
  submitting,
  mainFields,
  subItemFields = [],
  defaultMainValues = {},
  defaultSubItemValues = [{}],
  addModificationText,
  onSubmit,
}) => {
  const [mainItem, setMainItem] = useState(defaultMainValues);
  const [subItems, setSubItems] = useState(defaultSubItemValues);
  const [mainRelations, setMainRelations] = useState<AnyObject>({});
  const [subRelations, setSubRelations] = useState<AnyObject>({});
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { currentProject } = useContext(CommonContext);

  const validateForm = () => {
    const mainFieldsValid = mainFields.every((mf) => {
      if (mf.required) {
        return mainItem[mf.internal_name] && mainItem[mf.internal_name] !== '';
      }
      return true;
    });

    const subItemsValid = subItems.every((item) =>
      subItemFields.every((mf) => {
        if (mf.required) {
          return item[mf.internal_name] && item[mf.internal_name] !== '';
        }
        return true;
      })
    );

    setIsFormValid(mainFieldsValid && subItemsValid);
  };

  useEffect(() => {
    const staticMainFields = mainFields.filter(
      (field) => field.field_type === 'static'
    );
    const staticSubItemFields = subItemFields.filter(
      (field) => field.field_type === 'static'
    );

    if (staticMainFields?.length) {
      const resObj1: any = {};
      staticMainFields.forEach(
        (field) => (resObj1[field.internal_name] = field.default_value)
      );
      setMainItem({
        ...mainItem,
        ...resObj1,
      });
    }

    if (staticSubItemFields?.length) {
      const resObj2: any = {};
      staticSubItemFields.forEach(
        (field) => (resObj2[field.internal_name] = field.default_value)
      );
      setSubItems(
        JSON.parse(JSON.stringify(subItems)).map((item: any) => ({
          ...item,
          ...resObj2,
        }))
      );
    }
    validateForm();
  }, [mainFields, subItems?.length, subItemFields]);

  const handleMainInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setMainItem({
      ...mainItem,
      [name]: value,
    });
  };

  const handleSubItemInputChange = (
    e: { target: { name: any; value: any } },
    itemIndex: number
  ) => {
    const { name, value } = e.target;
    const newSubItems = subItems.slice();
    newSubItems[itemIndex] = { ...newSubItems[itemIndex], [name]: value };
    setSubItems(newSubItems);
  };

  const deleteSubItem = (itemIndex: number) => {
    const newSubItems = subItems.slice();
    newSubItems[itemIndex]['_destroy'] = true;
    setSubItems(newSubItems);
  };

  const fetchRelations = async (
    relationsToFetch: string[],
    type: 'sub' | 'main'
  ) => {
    await Promise.all(
      relationsToFetch.map(async (relationToFetch) => {
        const entityToFetch =
          (relationToFetch === 'product_modification_id'
            ? 'product'
            : relationToFetch === 'manager_id'
              ? 'user'
              : relationToFetch.slice(0, -3)) + 's';
        const relationItems = await BaseApiClient.getIndex(
          currentProject?.id,
          entityToFetch
        );
        return {
          [relationToFetch]: relationItems
            .map((el) =>
              relationToFetch === 'product_modification_id'
                ? el.product_modifications.map((pm: AnyObject) => ({
                    groupLabel: [
                      el.additional_fields?.secondaryid,
                      el.additional_fields?.n_collection,
                      el.title,
                    ]
                      .filter((f) => !!f)
                      .join(' - '),
                    label: `${pm.count} || ${pm.parsed_title}`,
                    price: pm.price,
                    id: pm.id,
                  }))
                : relationToFetch === 'user_role_id'
                  ? {
                      label: localize.users.roles[el.title],
                      id: el.id,
                    }
                  : {
                      label: el.name || el.title,
                      id: el.id,
                    }
            )
            .flat(),
        };
      })
    ).then((res) => {
      const currRelations: AnyObject = {};
      res.map((r) => (currRelations[Object.keys(r)[0]] = Object.values(r)[0]));
      type === 'sub'
        ? setSubRelations(currRelations)
        : setMainRelations(currRelations);
    });
  };

  useEffect(() => {
    const relationsToFetch = [
      ...mainFields
        .filter((mf) => mf.internal_name.endsWith('_id'))
        .map((mf) => mf.internal_name),
    ];
    if (relationsToFetch.length) {
      setLoadingMain(true);
      fetchRelations(relationsToFetch, 'main').finally(() =>
        setLoadingMain(false)
      );
    }
  }, [mainFields]);

  useEffect(() => {
    const relationsToFetch = [
      ...subItemFields
        .filter((mf) => mf.internal_name.endsWith('_id'))
        .map((mf) => mf.internal_name),
    ];
    if (relationsToFetch.length) {
      setLoadingSub(true);
      fetchRelations(relationsToFetch, 'sub').finally(() =>
        setLoadingSub(false)
      );
    }
  }, [subItemFields]);

  useEffect(() => {
    validateForm();
  }, [mainItem, subItems]);

  if (loadingMain) return <Loading />;

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(mainItem, subItems);
      }}
    >
      <Typography color='#0C0C0C' variant='h5'>
        {localize.products.form.requiredFields}
      </Typography>
      <FieldsContainer mt='20px' mb='10px'>
        {mainFields
          .filter((field) => field.required && field.field_type !== 'files')
          .map((mf) => (
            <FormField
              key={mf.internal_name}
              value={
                mainItem[mf.internal_name] === undefined
                  ? ''
                  : mainItem[mf.internal_name]
              }
              label={mf.label}
              name={mf.internal_name}
              onChange={handleMainInputChange}
              fieldType={mf.field_type}
              localizedField={mf.localized_field}
              options={mf.options}
              required={mf.required}
              disabled={!mf.editable}
              autocomplete={
                mf.internal_name.endsWith('_id')
                  ? {
                      options: mainRelations[mf.internal_name] || [],
                      onChange: (_, newValue: any) => {
                        setMainItem({
                          ...mainItem,
                          [mf.internal_name]: newValue,
                        });
                      },
                    }
                  : undefined
              }
            />
          ))}
      </FieldsContainer>
      {mainFields
        .filter((field) => field.field_type === 'files')
        .map((mf) => (
          <Box key={mf.internal_name} mb='20px'>
            <Typography color='#0C0C0C' variant='body1' mb='5px'>
              {mf.label}
            </Typography>
            <Box maxWidth='600px'>
              <FileUpload
                onChange={(files) =>
                  handleMainInputChange({
                    target: { name: mf.internal_name, value: files },
                  })
                }
                accept={{ 'image/*': [] }}
                value={
                  mainItem[mf.internal_name] === undefined
                    ? []
                    : (mainItem[mf.internal_name] as string[])
                }
              />
            </Box>
          </Box>
        ))}
      <Divider sx={{ margin: '30px 0', borderColor: '#DFDDF9' }} />
      <Typography color='#0C0C0C' variant='h5' mb='30px'>
        {localize.products.form.additionalFields}
      </Typography>
      <Alert
        severity='info'
        sx={{ mb: '10px' }}
        icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
      >
        {localize.products.edit.additionalFieldsText}
        <NavLink
          to='/cp/templates/edit/product'
          style={{ color: '#0A7AFF', marginLeft: '5px' }}
        >
          {localize.products.edit.additionalFieldsLink}
        </NavLink>
      </Alert>
      <FieldsContainer mt='20px'>
        {mainFields
          .filter((field) => !field.required && field.field_type !== 'files')
          .map((mf) => (
            <FormField
              key={mf.internal_name}
              value={
                mainItem[mf.internal_name] === undefined
                  ? ''
                  : mainItem[mf.internal_name]
              }
              label={mf.label}
              name={mf.internal_name}
              onChange={handleMainInputChange}
              fieldType={mf.field_type}
              localizedField={mf.localized_field}
              options={mf.options}
              required={mf.required}
              disabled={!mf.editable}
              autocomplete={
                mf.internal_name.endsWith('_id')
                  ? {
                      options: mainRelations[mf.internal_name] || [],
                      onChange: (_, newValue: any) => {
                        setMainItem({
                          ...mainItem,
                          [mf.internal_name]: newValue,
                        });
                      },
                    }
                  : undefined
              }
            />
          ))}
      </FieldsContainer>
      <Divider sx={{ margin: '30px 0', borderColor: '#DFDDF9' }} />
      <Typography color='#0C0C0C' variant='h5' mb='30px'>
        {localize.products.form.modifications}
      </Typography>
      <Alert
        severity='info'
        sx={{ mb: '20px' }}
        icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
      >
        {localize.products.edit.modificationsText}
        <NavLink
          style={{ color: '#0A7AFF', marginLeft: '5px' }}
          to='/cp/templates/edit/product_modification'
        >
          {localize.products.edit.modificationsLink}
        </NavLink>
      </Alert>
      {subItemFields.length ? (
        <>
          {!loadingSub &&
            subItems.map((item, i) => {
              if (item['_destroy']) return null;
              return (
                <SubItemContainer key={`${item.id}-${i}`}>
                  <SubItemInnerContainer>
                    {subItemFields.map((mf) => (
                      <FormField
                        key={`${item.id}-${i}-${mf.internal_name}`}
                        value={item[mf.internal_name] || ''}
                        label={mf.label}
                        id={`pm_${mf.internal_name}`}
                        name={mf.internal_name}
                        required={mf.required}
                        fieldType={mf.field_type}
                        options={mf.options}
                        localizedField={mf.localized_field}
                        disabled={!mf.editable}
                        onChange={(e) => handleSubItemInputChange(e, i)}
                        autocomplete={
                          mf.internal_name.endsWith('_id')
                            ? {
                                options: subRelations[mf.internal_name] || [],
                                onChange: (_, newValue: any) => {
                                  const newSubItems = subItems.slice();
                                  const newFields = newValue?.price
                                    ? {
                                        [mf.internal_name]: newValue,
                                        fixed_price: +newValue.price,
                                      }
                                    : {
                                        [mf.internal_name]: newValue,
                                      };
                                  newSubItems[i] = {
                                    ...newSubItems[i],
                                    ...newFields,
                                  };

                                  setSubItems(newSubItems);
                                },
                              }
                            : undefined
                        }
                      />
                    ))}
                    {subItems.filter((item) => !item['_destroy']).length > 1 ? (
                      <Button
                        startIcon={<DeleteIcon fill='#fff' />}
                        variant='danger'
                        sx={{ margin: 'auto 0 0 auto' }}
                        onClick={(): void => {
                          deleteSubItem(i);
                        }}
                      >
                        {localize.general.delete}
                      </Button>
                    ) : null}
                  </SubItemInnerContainer>
                </SubItemContainer>
              );
            })}
          <Button
            variant='transparent'
            sx={{ mt: '20px', ml: '10px' }}
            startIcon={
              loadingSub ? (
                <CircularProgress color='inherit' size={20} />
              ) : (
                <AddIcon />
              )
            }
            onClick={() =>
              !loadingSub ? setSubItems([...subItems, {}]) : undefined
            }
          >
            {loadingSub ? '' : addModificationText}
          </Button>
        </>
      ) : null}
      <Actions>
        <Button
          size='large'
          type='submit'
          variant='rounded'
          disabled={submitting || !isFormValid}
        >
          {submitting ? (
            <CircularProgress size={26} thickness={6} color='primary' />
          ) : (
            localize.general.submit
          )}
        </Button>
        <Button
          variant='transparent'
          onClick={() => {
            setMainItem(defaultMainValues);
            setSubItems(defaultSubItemValues);
          }}
          disabled={submitting}
        >
          {localize.general.cancel}
        </Button>
      </Actions>
    </Container>
  );
};

export default ProductForm;

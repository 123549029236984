import { Button, CircularProgress, styled, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from '@mui/material';
import { StyledInput } from '../../components/StyledInput';
import { MainHeadingText } from './Notifications';
import { NotificationContext } from '../../contexts/NotificationContext';
import localize from '../../localize';
import { FaqAccordion } from './FaqAccordion';
import { create } from '../landing-pages/api/RequestForm';

const StyledBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
}));

export const SupportForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
}));

const InputGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
    '&.purple-text-label': {
      color: '#8D83FE',
    },
    '': {
      padding: '0',
    },
  },
}));

const ReferencesLinksContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
}));

const ReferenceLink = styled(Link)(() => ({
  color: '#7B75CB',
  fontSize: '14px',
  fontFamily: 'Montserrat, sans-serif',
  textDecoration: 'underline',
  '&:hover': {
    color: '#AC9BFF',
  },
}));

export const Support = () => {
  const { setNotification } = useContext(NotificationContext);
  const [submitting, setSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    message: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const isValidEmail = useCallback((email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }, []);

  useEffect(() => {
    const isValidForm =
      (formData.message !== '' && formData.email !== '') ||
      formData.email !== '' ||
      isValidEmail(formData.email);
    setIsFormValid(isValidForm);
  }, [formData.email, formData.message, isValidEmail]);

  const onSubmit = async () => {
    try {
      if (formData.email || formData.message) {
        setSubmitting(true);
        create({
          email: formData.email,
          comment: formData.message,
        })
          .then(() => {
            setNotification({
              severity: 'success',
              message: localize.landing.successSentMessage,
            });

            setFormData({ email: '', message: '' });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    } catch (error) {
      setNotification({
        severity: 'error',
        message: localize.general.unexpectedError,
      });
    }
  };

  const referencesLinks = [
    {
      id: '1',
      text: localize.profile.edit.support.references.links.integration,
      link: '#integration',
    },
    {
      id: '2',
      text: localize.profile.edit.support.references.links.paymentMethods,
      link: '#paymentMethods',
    },
    {
      id: '3',
      text: localize.profile.edit.support.references.links.sellStatistics,
      link: '#sellStatistics',
    },
  ];

  return (
    <StyledBox sx={{ maxWidth: '590px' }}>
      <StyledBox>
        <MainHeadingText>
          {localize.profile.edit.support.faq.title}
        </MainHeadingText>
        <FaqAccordion data={localize.profile.edit.support.faq.accordionData} />
        <SupportForm>
          <StyledBox sx={{ gap: '8px' }}>
            <MainHeadingText>
              {localize.profile.edit.support.contactSupport.title}
            </MainHeadingText>
            <Typography
              sx={{
                fontSize: '12px',
                color: '#777',
              }}
            >
              {localize.profile.edit.support.contactSupport.description}
            </Typography>
          </StyledBox>
          <InputGroup>
            <LabelGroup>
              <label htmlFor='email'>
                {localize.profile.edit.support.contactSupport.labels.email}
              </label>
              <StyledInput
                id='email'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderColor: '#f1f1f1',
                  },
                }}
                value={formData.email}
                placeholder={
                  localize.profile.edit.mainSettings.labels.emailPlaceholder
                }
                helperText={
                  formData.email !== '' && !isValidEmail(formData.email)
                    ? localize.profile.edit.mainSettings.labels.formatError
                    : ' '
                }
                error={formData.email !== '' && !isValidEmail(formData.email)}
                name='email'
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  })
                }
              />
            </LabelGroup>
            <LabelGroup>
              <label htmlFor='message'>
                {localize.profile.edit.support.contactSupport.labels.message}
              </label>
              <StyledInput
                id='message'
                value={formData.message}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderColor: '#f1f1f1',
                  },
                }}
                placeholder={localize.profile.edit.placeholderText}
                name='message'
                multiline
                rows={4}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    message: event.target.value,
                  })
                }
              />
            </LabelGroup>
            <Button
              variant='rounded'
              disabled={!isFormValid || submitting}
              onClick={() => onSubmit()}
              endIcon={
                submitting ? (
                  <CircularProgress size={16} color='inherit' />
                ) : null
              }
            >
              {localize.profile.edit.support.contactSupport.submitButton}
            </Button>
          </InputGroup>
        </SupportForm>
        {/* <StyledBox>
          <MainHeadingText>
            {localize.profile.edit.support.references.title}
          </MainHeadingText>
          <ReferencesLinksContainer>
            {referencesLinks.map(({ id, text, link }) => (
              <ReferenceLink
                key={id}
                href={link}
                target='_blank'
                rel='noreferrer'
              >
                {text}
              </ReferenceLink>
            ))}
          </ReferencesLinksContainer>
        </StyledBox> */}
      </StyledBox>
    </StyledBox>
  );
};

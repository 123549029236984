import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Tooltip,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import localize from '../../localize';
import { projectSettingsFormFields } from './data';
import { useAsyncError } from '../../hooks/useAsyncError';
import { CommonContext } from '../../contexts/CommonContext';
import { uploadBlobToAws } from '../../utils/aws';
import { ProjectApiClient } from '../../api/ProjectApiClient';
import { ProjectUploadApiClient } from '../../api/ProjectUploadApiClient';
import { NotificationContext } from '../../contexts/NotificationContext';
import { LogoUpload } from '../../components/LogoUpload/LogoUpload';
import {
  ArrayToIntersectionType,
  FormTextField,
  ImageData as ImgData,
} from '../../utils/types';

const MainSettingsForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '54px',
}));

const StartInputWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'center',
  marginTop: '20px',
}));

const InputsGroup = styled('div')(() => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '24px',
  rowGap: '12px',
  alignItems: 'center',
  marginTop: '20px',
}));

const BottomInputsGroup = styled('div')(() => ({
  display: 'grid',
  width: '100%',
  columnGap: '24px',
  rowGap: '12px',
  gridTemplateColumns: '1fr 1fr',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
    '&.purple-text-label': {
      color: '#8D83FE',
    },
  },
}));

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '53px',
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}));

export const ButtonsBox = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-start',
}));

type CategoryData = {
  label: string;
  description: string;
};

type LegalType = 'ФОП' | 'ТОВ';

export const ProjectSettings = () => {
  const formFieldIds = projectSettingsFormFields.map((field) => field.id);
  type FormData = Record<ArrayToIntersectionType<typeof formFieldIds>, string>;

  const { currentProject } = useContext(CommonContext);
  const { setNotification } = useContext(NotificationContext);
  const throwError = useAsyncError();

  const [legalType, setLegalType] = useState<LegalType>(
    'ФОП' || currentProject.legal_type
  );

  const [imageData, setImageData] = useState<ImgData>({
    path: currentProject?.logo_url || null,
    files: [],
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const initialStateFormData = useMemo(() => {
    return {
      ...formFieldIds.reduce(
        (acc, key) => ({ ...acc, [key]: currentProject[key] || '' }),
        {} as FormData
      ),
    };
  }, [currentProject, formFieldIds]);
  const [formData, setFormData] = useState<FormData>(initialStateFormData);

  const resetFormValues = useCallback(() => {
    setLegalType(currentProject?.legal_type || 'ФОП');
    setImageData({
      path: currentProject?.logo_url || null,
      files: [],
    });
    setFormData({
      ...Object.keys(initialStateFormData).reduce(
        (acc, key) => ({ ...acc, [key]: currentProject[key] || '' }),
        {} as FormData
      ),
    });
  }, [currentProject, initialStateFormData]);

  useEffect(() => {
    if (currentProject?.id) {
      const isFormValid = !!formData.title;

      setFormIsValid(isFormValid);
    }
  }, [currentProject, formData]);

  const onSubmit = () => {
    setSubmitting(true);
    const uploadAvatarPromise = imageData.files.length
      ? uploadBlobToAws({
          projectId: currentProject?.id as string,
          ApiClient: ProjectUploadApiClient,
          contentType: imageData.files[0].type,
          files: imageData.files,
        })
      : Promise.resolve({ success: [imageData.path] });

    uploadAvatarPromise
      .then((res) => {
        ProjectApiClient.update(currentProject?.id as string, {
          project: {
            ...formData,
            legal_type: legalType,
            logo: res.success[0],
          },
        });
      })

      .then(() => {
        setNotification({
          severity: 'success',
          message: localize.general.successUpdateMessage,
        });
      })
      .catch((error) => {
        throwError(error);
        setNotification({
          severity: 'error',
          message: localize.general.errorMessage,
        });
      })
      .finally(() => {
        setSubmitting(false);
        window.location.reload();
      });
  };

  const onLegalTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegalType(event.target.value as LegalType);
  };

  const topInputs = projectSettingsFormFields.filter(
    ({ id }) => id === 'legal_name' || id === 'title' || id === 'contact_person'
  );
  const categoryInput = projectSettingsFormFields.find(
    ({ id }) => id === 'category'
  );
  // TODO: fields which exist in design, but not on backend
  // const middleInputs = projectSettingsFormFields.filter(
  //   ({ id }) =>
  //     id === 'min_order' ||
  //     id === 'region' ||
  //     id === 'delivery_time' ||
  //     id === 'delivery_text' ||
  //     id === 'return_of_goods'
  // );
  const bottomInputs = projectSettingsFormFields.filter(
    ({ id }) => id === 'description'
  );

  const hasFormChanged = useMemo(() => {
    return (
      JSON.stringify(initialStateFormData) !== JSON.stringify(formData) ||
      legalType !== (currentProject.legal_type || 'ФОП') ||
      imageData.path !== (currentProject.logo_url || null)
    );
  }, [
    initialStateFormData,
    formData,
    legalType,
    currentProject.legal_type,
    currentProject.logo_url,
    imageData.path,
  ]);

  return (
    <Grid item xs={12}>
      <MainSettingsForm
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <StyledFormControl sx={{ maxWidth: '992px', rowGap: '32px' }}>
          <StartInputWrapper>
            <LogoUpload imageData={imageData} setImageData={setImageData} />
          </StartInputWrapper>
          <RadioGroup
            color='primary'
            aria-labelledby='legal-type-group-label'
            value={legalType}
            onChange={onLegalTypeChange}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '56px',
              width: '100%',
              justifyContent: 'flex-start',
            }}
            name='legal-type-radio-buttons-group'
          >
            <FormControlLabel
              value='ФОП'
              control={<Radio color='info' />}
              label={localize.projects.edit.main.labels.fop}
            />
            <FormControlLabel
              value='ТОВ'
              control={<Radio color='info' />}
              label={localize.projects.edit.main.labels.tov}
            />
          </RadioGroup>
          <InputsGroup>
            {(topInputs as FormTextField[]).map((input, index) => (
              <LabelGroup key={input.id}>
                <label htmlFor={input.id}>
                  {input.id === 'legal_name' && legalType === 'ТОВ'
                    ? localize.projects.edit.main.labels.tovName
                    : input.label}
                </label>
                <TextField
                  id={input.id}
                  value={formData[input.id as keyof FormData]}
                  placeholder={input.placeholder}
                  required={input.required}
                  type={input.type}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      [input.id]: event.target.value,
                    });
                  }}
                  name={input.id}
                  helperText={' '}
                />
              </LabelGroup>
            ))}
          </InputsGroup>
          <LabelGroup>
            <FormLabel
              component={'label'}
              sx={{ paddingBottom: '24px', color: '#0c0c0c' }}
            >
              {localize.projects.edit.main.labels.category}
            </FormLabel>
            <RadioGroup
              value={formData.category}
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
              }}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  category: event.target.value,
                });
              }}
            >
              {((categoryInput as FormTextField)
                .options as CategoryData[])!.map((item, index) => (
                <Tooltip key={index} title={item.description} arrow>
                  <FormControlLabel
                    value={item.label}
                    control={<Radio />}
                    label={item.label}
                  />
                </Tooltip>
              ))}
            </RadioGroup>
          </LabelGroup>

          <StyledBox>
            {/* TODO: fields which exist in design, but not on backend */}
            {/* <FormLabel
              component={'label'}
              sx={{ paddingBottom: '12px', color: '#0c0c0c' }}
            >
              {localize.projects.edit.main.labels.coopTerms}
            </FormLabel>
            <InputsGroup>
              {(middleInputs as FormTextField[]).map((input, index) => {
                if (input.type === 'select' && input.options) {
                  return (
                    <LabelGroup key={input.id}>
                      <label htmlFor={input.id}>
                        {input.label}{' '}
                        {input.labelNote && (
                          <span className='grey-text'>{input.labelNote}</span>
                        )}
                      </label>
                      <TextField
                        id={input.id}
                        value={
                          formData[input.id as keyof FormData] ||
                          input.placeholder
                        }
                        select
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            [input.id]: event.target.value,
                          });
                        }}
                        name={input.id}
                        helperText={' '}
                      >
                        <MenuItem value={input.placeholder}>
                          <span>{input.placeholder}</span>
                        </MenuItem>
                        {(input.options as string[])!.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </LabelGroup>
                  );
                }

                return (
                  <LabelGroup key={input.id}>
                    <label htmlFor={input.id}>{input.label}</label>
                    <TextField
                      id={input.id}
                      value={formData[input.id as keyof FormData]}
                      placeholder={input.placeholder}
                      type={input.type}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          [input.id]: event.target.value,
                        });
                      }}
                      name={input.id}
                      helperText={' '}
                    />
                  </LabelGroup>
                );
              })}
            </InputsGroup> */}
            <BottomInputsGroup>
              {(bottomInputs as FormTextField[]).map((input, index) => {
                return (
                  <LabelGroup key={input.id}>
                    <label htmlFor={input.id}>{input.label}</label>
                    <TextField
                      {...(input.type === 'textarea' && {
                        multiline: true,
                        rows: 4,
                      })}
                      id={input.id}
                      value={formData[input.id as keyof FormData]}
                      placeholder={input.placeholder}
                      type={input.type}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          [input.id]: event.target.value,
                        });
                      }}
                      name={input.id}
                      helperText={' '}
                    />
                  </LabelGroup>
                );
              })}
            </BottomInputsGroup>
          </StyledBox>

          <ButtonsBox>
            <Button
              variant='rounded'
              type='submit'
              disabled={submitting || !formIsValid || !hasFormChanged}
              endIcon={
                submitting ? (
                  <CircularProgress size={16} color='inherit' />
                ) : null
              }
            >
              {localize.projects.edit.buttonApply}
            </Button>
            <Button
              variant='transparent'
              onClick={() => resetFormValues()}
              disabled={submitting || !hasFormChanged}
            >
              {localize.projects.edit.buttonClear}
            </Button>
          </ButtonsBox>
        </StyledFormControl>
      </MainSettingsForm>
    </Grid>
  );
};

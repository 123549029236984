import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { ReactComponent as DeleteIcon } from '../../assets/delete-item.svg';

import { CommonContext } from '../../contexts/CommonContext';
import { AnyObject } from '../../api/anyObjectTypes';
import { BookingApiClient } from '../../api/BookingApiClient';
import { formatDateTime, timeLeft } from '../../utils/time';
import localize from '../../localize';
import Loading from '../../components/Loading/Loading';
import Page, { PageAction } from '../../components/Page/Page';
import MainCard from '../../components/MainCard/MainCard';
import { NotificationContext } from '../../contexts/NotificationContext';

const baseMainFields = [
  {
    field: 'product.title',
    headerName: localize.bookings.columns.product_title,
    link: {
      url: '/cp/products/',
      id: 'product.id',
    },
  },
  {
    field: 'count',
    headerName: localize.bookings.columns.count,
  },
  {
    field: 'user_name',
    headerName: localize.bookings.columns.user_name,
  },
  {
    field: 'product_modification.parsed_title',
    headerName: localize.bookings.columns.product_modification_title,
  },
  {
    field: 'created_at',
    headerName: localize.bookings.columns.created_at,
  },
  {
    field: 'updated_at',
    headerName: localize.bookings.columns.updated_at,
  },
  {
    field: 'order_id',
    headerName: localize.bookings.columns.order_id,
    link: {
      url: '/cp/orders/',
      id: 'order_id',
    },
  },
  {
    field: 'enddate',
    headerName: localize.bookings.columns.booked_for_hours,
  },
];

const StatusText = styled(Typography)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '10px',
  padding: '6px 10px',
  borderRadius: '100px',
  color: '#0C0C0C',
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '14.63px',
}));

const CommentText = styled(Typography)(() => ({
  width: '500px',
  minHeight: '200px',
  borderRadius: '20px',
  border: '1px solid #D9D9D9',
  padding: '8px 16px',
  color: '#464646',
  background: '#fff',
}));

export const BookingShowPage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentProject, currentUser } = useContext(CommonContext);
  const { setNotification } = useContext(NotificationContext);
  const [item, setItem] = useState<AnyObject>({});
  const [mainLoading, setMainLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const deleteItem = (item: AnyObject) => {
    setButtonLoading(true);
    BookingApiClient.remove(currentProject.id, item.id)
      .then(() => {
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
      })
      .catch((e) => {
        setNotification({
          severity: 'error',
          message: localize.general.unexpectedError,
        });
      })
      .finally(() => {
        setButtonLoading(false);
        navigate(`/cp/bookings`);
      });
  };

  const pageActions = useMemo(() => {
    const editAction = {
      Component: (
        <NavLink
          to={`/cp/bookings/edit/${id}`}
          style={{
            textDecoration: 'none',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography color='#5B4D8F' variant='h5' p='10px'>
            {localize.general.edit}
          </Typography>
        </NavLink>
      ),
    };

    const deleteAction = {
      Component: (
        <Button
          variant='danger'
          startIcon={<DeleteIcon fill='#fff' />}
          disabled={buttonLoading}
          onClick={() => deleteItem(item)}
        >
          {buttonLoading ? (
            <CircularProgress size={26} thickness={6} />
          ) : (
            localize.general.delete
          )}
        </Button>
      ),
    };

    const res: PageAction[] = [];

    if (!item.order_id) res.push(editAction);
    if (
      !item.order_id &&
      currentUser.user_permissions.template?.update?.length !== 0
    )
      res.push(deleteAction);

    return res;
  }, [id, item]);

  const subtitle = useMemo(
    () =>
      timeLeft(new Date(item.enddate)) !== '0' || item.status !== 'Active' ? (
        <StatusText
          sx={{
            background:
              item.status === 'Stock updated' ? '#ffbf1c7b' : '#C8FFD7',
          }}
        >
          {
            <Brightness1Icon
              sx={{
                color: item.status === 'Stock updated' ? '#FFBF1C' : '#53C17F',
                width: '12px',
                height: '12px',
              }}
            />
          }
          {localize.bookings.status[item.status]}
        </StatusText>
      ) : (
        <StatusText
          sx={{
            background: '#fa3b3b6c',
          }}
        >
          <Brightness1Icon
            sx={{
              color: '#FA3B3B',
              width: '12px',
              height: '12px',
            }}
          />

          {localize.bookings.status['Disabled']}
        </StatusText>
      ),
    [item, id]
  );

  const getText = (field: string): string | null => {
    const array = field.split('.');
    const first = item[array[0]];
    if (field === 'updated_at' || field === 'created_at') {
      return formatDateTime(first);
    }
    if (field === 'enddate') {
      return timeLeft(new Date(item.enddate)) !== '0'
        ? timeLeft(new Date(item.enddate))
        : null;
    }

    return !first || typeof first !== 'object'
      ? first
      : first[array.pop() || ''];
  };

  const preparePageData = async (id: string): Promise<void> => {
    const res = await BookingApiClient.getShow(currentProject.id, id);
    setItem(res);
  };

  useEffect(() => {
    if (!id) return;

    setMainLoading(true);
    preparePageData(id).finally(() => setMainLoading(false));
  }, [id, currentProject]);

  if (mainLoading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page
      title={
        <Typography
          variant='h2'
          color='#000'
          sx={{ display: 'inline', mr: '10px' }}
        >
          {localize.bookings.show.title}
        </Typography>
      }
      subtitle={subtitle}
      actions={pageActions}
    >
      <Box width='100%' display='flex' flexDirection='column' gap='20px'>
        <MainCard title={localize.bookings.general.data}>
          <List sx={{ flexDirection: 'column', display: 'flex', gap: '5px' }}>
            {baseMainFields.map((field) =>
              getText(field.field) ? (
                <ListItem key={field.field} sx={{ gap: '10px', padding: '0' }}>
                  <Typography color='#0C0C0C' variant='h5'>
                    {field.headerName}:
                  </Typography>
                  {field.link ? (
                    <NavLink
                      to={`${field.link.url}${getText(field.link.id)}`}
                      style={{ textDecoration: 'none', color: '#464646' }}
                    >
                      <Typography color='#464646' variant='body1'>
                        {getText(field.field)}
                      </Typography>
                    </NavLink>
                  ) : (
                    <Typography color='#464646' variant='body1'>
                      {getText(field.field)}
                    </Typography>
                  )}
                </ListItem>
              ) : null
            )}
          </List>
        </MainCard>
        {item.comment && (
          <MainCard
            title={localize.bookings.general.comment}
            subtitle={localize.bookings.general.comment_text}
          >
            <CommentText variant='body1'>{item.comment}</CommentText>
          </MainCard>
        )}
      </Box>
    </Page>
  );
};

export default BookingShowPage;

import { FC, useContext, useEffect, useState } from 'react';
import {
  CircularProgress,
  Button,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import localize from '../../localize';
import { AnyObject } from '../../api/anyObjectTypes';
import { BaseApiClient } from '../../api/BaseApiClient';
import { CommonContext } from '../../contexts/CommonContext';
import FormField from '../FormField/FormField';
import MainCard from '../MainCard/MainCard';
import Loading from '../Loading/Loading';

import { ReactComponent as InfoIconSvg } from '../../assets/info-circle.svg';

const Actions = styled(Grid)(({ theme }) => ({
  display: 'flex',
  padding: '10px 50px 10px 10px',
  gap: '20px',
}));

type BookingsFormProps = {
  onSubmit: ({
    mainItem,
    basicMainLabels,
    mainFields,
  }: {
    mainItem: AnyObject;
    basicMainLabels: AnyObject;
    mainFields: AnyObject[];
  }) => void;
  submitting: boolean;
  values?: AnyObject;
};

const BookingsForm: FC<BookingsFormProps> = ({
  onSubmit,
  submitting,
  values,
}) => {
  const basicMainLabels: AnyObject = {
    product_modification_id: localize.bookings.columns.product_modification_id,
    count: localize.bookings.columns.count,
    user_id: localize.bookings.columns.select_user,
    booked_for_hours: (
      <>
        {
          <Tooltip
            title={localize.bookings.create.endDateTooltip}
            placement='top'
          >
            <InfoIconSvg
              width='24px'
              height='24px'
              fill='#9B96EB'
              style={{
                margin: '0 5px -5px 0',
                position: 'absolute',
                right: '0',
                top: '0',
              }}
            />
          </Tooltip>
        }
        {localize.bookings.columns.booked_for_hours}
      </>
    ),
  };
  const [mainItem, setMainItem] = useState(values || {});
  const [mainRelations, setMainRelations] = useState<AnyObject>({});
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [loadingMain, setLoadingMain] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const { currentProject } = useContext(CommonContext);

  const validateForm = () => {
    const mainFieldsValid = mainFields.every((mf) => {
      if (mf.required) {
        return mainItem[mf.internal_name] && mainItem[mf.internal_name] !== '';
      }
      return true;
    });

    setIsFormValid(mainFieldsValid);
  };

  const onClickSubmit = () => {
    onSubmit({
      mainItem,
      mainFields: [
        ...mainFields,
        {
          editable: true,
          hidden: true,
          internal_name: 'comment',
          label: localize.bookings.columns.comment,
          parsable: false,
          required: false,
        },
      ],
      basicMainLabels: {
        ...basicMainLabels,
        comment: localize.bookings.columns.comment,
      },
    });
  };

  const handleMainInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setMainItem({
      ...mainItem,
      [name]: value,
    });
    validateForm();
  };

  const fetchRelations = async (
    relationsToFetch: string[],
    type: 'sub' | 'main'
  ) => {
    await Promise.all(
      relationsToFetch.map(async (relationToFetch) => {
        const entityToFetch =
          (relationToFetch === 'product_modification_id'
            ? 'product'
            : relationToFetch === 'manager_id'
              ? 'user'
              : relationToFetch.slice(0, -3)) + 's';
        const relationItems = await BaseApiClient.getIndex(
          currentProject?.id,
          entityToFetch
        );
        return {
          [relationToFetch]: relationItems
            .map((el) =>
              relationToFetch === 'product_modification_id'
                ? el.product_modifications.map((pm: AnyObject) => ({
                    groupLabel: [
                      el.additional_fields?.secondaryid,
                      el.additional_fields?.n_collection,
                      el.title,
                    ]
                      .filter((f) => !!f)
                      .join(' - '),
                    label: `${pm.count} || ${pm.parsed_title}`,
                    price: pm.price,
                    id: pm.id,
                  }))
                : relationToFetch === 'user_role_id'
                  ? {
                      label: localize.users.roles[el.title],
                      id: el.id,
                    }
                  : {
                      label: el.name || el.title,
                      id: el.id,
                    }
            )
            .flat(),
        };
      })
    ).then((res) => {
      const currRelations: AnyObject = {};
      res.map((r) => (currRelations[Object.keys(r)[0]] = Object.values(r)[0]));
      setMainRelations(currRelations);
    });
  };

  useEffect(() => {
    const relationsToFetch = [
      ...mainFields
        .filter((mf) => mf.internal_name.endsWith('_id'))
        .map((mf) => mf.internal_name),
    ];
    if (relationsToFetch.length) {
      setLoadingMain(true);
      fetchRelations(relationsToFetch, 'main').finally(() =>
        setLoadingMain(false)
      );
    }
  }, [mainFields]);

  useEffect(() => {
    setMainFields([
      ...Object.keys(basicMainLabels || {}).map((el) => ({
        editable: true,
        hidden: true,
        internal_name: el,
        label: basicMainLabels[el],
        parsable: false,
        required: el !== 'comment' && el !== 'user_id',
      })),
    ]);
  }, [currentProject]);

  if (loadingMain) return <Loading />;

  return (
    <Grid
      container
      component='form'
      spacing={4}
      alignItems='flex-start'
      alignSelf='flex-start'
      onSubmit={(e) => {
        e.preventDefault();
        onClickSubmit();
      }}
    >
      <Grid item xs={12}>
        <MainCard title={localize.bookings.general.data}>
          <Grid container spacing={2}>
            {mainFields.map((mf) => (
              <Grid key={mf.internal_name} item xs={4}>
                <FormField
                  value={
                    mainItem[mf.internal_name] === undefined
                      ? ''
                      : mainItem[mf.internal_name]
                  }
                  label={mf.label}
                  name={mf.internal_name}
                  onChange={handleMainInputChange}
                  fieldType={mf.field_type}
                  localizedField={mf.localized_field}
                  options={mf.options}
                  required={mf.required}
                  disabled={!mf.editable}
                  autocomplete={
                    mf.internal_name.endsWith('_id')
                      ? {
                          options: mainRelations[mf.internal_name] || [],
                          onChange: (_, newValue: any) => {
                            setMainItem({
                              ...mainItem,
                              [mf.internal_name]: newValue,
                            });
                          },
                        }
                      : undefined
                  }
                />
              </Grid>
            ))}
          </Grid>
        </MainCard>
      </Grid>
      <Grid item xs={8}>
        <MainCard
          title={localize.bookings.general.comment}
          subtitle={localize.bookings.general.comment_text}
          tooltipText={localize.bookings.create.commentTooltip}
        >
          <TextField
            id='comment'
            name='comment'
            multiline
            fullWidth
            sx={{ marginTop: '10px' }}
            rows={6}
            value={mainItem.comment}
            placeholder={localize.bookings.create.commentPlaceholder}
            onChange={handleMainInputChange}
          />
        </MainCard>
      </Grid>
      <Actions item xs={12}>
        <Button
          size='large'
          type='submit'
          variant='rounded'
          disabled={submitting || !isFormValid}
        >
          {submitting ? (
            <CircularProgress size={26} thickness={6} color='primary' />
          ) : (
            localize.general.submit
          )}
        </Button>
        <Button
          variant='transparent'
          onClick={() => {
            setMainItem(values || {});
          }}
          disabled={submitting}
        >
          {localize.general.cancel}
        </Button>
      </Actions>
    </Grid>
  );
};

export default BookingsForm;

import { FC, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateTimePicker } from '@mui/x-date-pickers';
import localize, { locale } from '../../localize';
import { useCloseOnClickOutside } from '../../hooks/useCloseOnClickOutside';

type FilterItemParams = {
  [key: string]: any;
};
type FilterProps = {
  mainItems: FilterItemParams[];
  subItems: FilterItemParams[];
  anyFiltersApplied: boolean;
  applyFilter(
    mainValues: FilterItemParams[],
    subValues: FilterItemParams[]
  ): void;
  resetFilter(): void;
  setOpenFilter(openFilter: boolean): void;
  openFilter: boolean;
};

const FilterNumber = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',

  p: {
    flex: '1 1 15%',
  },
}));

const FilterItemDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',

  '& span.MuiCheckbox-root': {
    padding: '2px 10px',
  },
}));

export const Filter: FC<FilterProps> = ({
  mainItems,
  subItems,
  anyFiltersApplied,
  applyFilter,
  resetFilter,
  setOpenFilter,
  openFilter,
}) => {
  const [mainValues, setMainValues] = useState(mainItems);
  const [subValues, setSubValues] = useState(subItems);
  const filterRef = useRef<HTMLDivElement | null>(null);

  const onValueChange = (item: any) => {
    if (item.value === undefined || item.value === '' || item.value === null)
      delete item.value;
    const newFilterItems = JSON.parse(
      JSON.stringify(!item.isSub ? mainValues : subValues)
    );
    const index = newFilterItems.findIndex((el: any) => el.id === item.id);
    if (index === -1) return;

    newFilterItems[index] = item;
    !item.isSub ? setMainValues(newFilterItems) : setSubValues(newFilterItems);
  };

  useCloseOnClickOutside(filterRef, (close, open) => {
    !open && setOpenFilter(false);
  });

  return (
    <Paper
      ref={filterRef}
      sx={{
        borderRadius: '24px',
        background: '#fff',
        border: '2px solid #DFDDF9',
        overflow: 'hidden',
        width: '100%',
        minHeight: '20vh',
        maxHeight: '70vh',
        maxWidth: '1000px',
        padding: '16px 32px',
        position: 'absolute',
        top: '50px',
        left: '0',
        zIndex: 2,
        overflowY: 'auto',
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        gap='10px'
        sx={{ position: 'sticky', top: '0', background: '#fff', zIndex: 5 }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '19.5px',
            color: '#464646',
            marginRight: 'auto',
          }}
        >
          {localize.filter.title}
        </Typography>
        <Button
          variant='transparent'
          onClick={() => {
            setOpenFilter(false);
            resetFilter();
          }}
        >
          {localize.filter.reset}
        </Button>
        <Button
          variant='rounded'
          onClick={() => {
            setOpenFilter(false);
            applyFilter(mainValues, subValues);
          }}
        >
          {localize.filter.apply}
        </Button>
      </Box>
      <List>
        {[
          ...mainValues,
          {},
          ...subValues.map((el) => {
            el.isSub = true;
            return el;
          }),
        ].map((item, index) => {
          if (
            index === 0 ||
            item.field_type === 'text' ||
            item.field_type === 'files'
          )
            return null;
          if (!Object.keys(item).length)
            return <Divider key='el-divider' style={{ margin: '20px 0' }} />;
          return (
            <ListItem key={item.label + index}>
              {item.options?.length ? (
                <Accordion sx={{ width: '100%' }}>
                  <AccordionSummary
                    sx={{ padding: '0', width: '250px' }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>
                      {item.localized_field[locale]?.label || item.label}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup
                      sx={{
                        width: '100%',
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: '10px',
                      }}
                    >
                      {item.options.map((option: string) => (
                        <FormControlLabel
                          key={`${item.id}-${option}`}
                          control={
                            <Checkbox
                              checked={item.value?.includes(option) || false}
                              onChange={(e, checked) =>
                                onValueChange({
                                  ...item,
                                  value: checked
                                    ? [
                                        ...(item.value?.filter(
                                          (el: string) => el !== option
                                        ) || []),
                                        option,
                                      ]
                                    : [
                                        ...(item.value?.filter(
                                          (el: string) => el !== option
                                        ) || []),
                                      ],
                                })
                              }
                              name={`${item.id}-${option}`}
                            />
                          }
                          label={
                            item.localized_field[locale]?.options[option] ||
                            option
                          }
                        />
                      ))}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <ListItemText
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px 30px',
                    flexWrap: 'wrap',
                  }}
                  primaryTypographyProps={{
                    minWidth: '120px',
                  }}
                  secondaryTypographyProps={{
                    width: item.options?.length ? '100%' : 'fit-content',
                    component: 'div',
                  }}
                  primary={item.localized_field[locale]?.label || item.label}
                  secondary={
                    <FilterItemDetails>
                      {item.field_type === 'number' && !item.options?.length
                        ? [
                            <FilterNumber key={`${item.internal_name}-from`}>
                              <Typography variant='body1'>
                                {localize.general.from}
                              </Typography>
                              <TextField
                                id={item.internal_name}
                                name={`${item.internal_name}-from`}
                                type='number'
                                placeholder={localize.general.typeNumber}
                                value={item.value ? item.value[0] : ''}
                                onChange={(e) =>
                                  onValueChange({
                                    ...item,
                                    value: [
                                      e.target.value,
                                      item.value ? item.value[1] : null,
                                    ],
                                  })
                                }
                              />
                            </FilterNumber>,
                            <FilterNumber key={`${item.internal_name}-to`}>
                              <Typography variant='body1'>
                                {localize.general.to}
                              </Typography>
                              <TextField
                                id={item.internal_name}
                                name={`${item.internal_name}-to`}
                                type='number'
                                placeholder={localize.general.typeNumber}
                                value={item.value ? item.value[1] : ''}
                                onChange={(e) =>
                                  onValueChange({
                                    ...item,
                                    value: [
                                      item.value ? item.value[0] : null,
                                      e.target.value,
                                    ],
                                  })
                                }
                              />
                            </FilterNumber>,
                          ]
                        : null}
                      {item.field_type === 'string' && !item.options?.length ? (
                        <TextField
                          id={item.internal_name}
                          name={item.internal_name}
                          type='text'
                          value={item.value || ''}
                          onChange={(e) =>
                            onValueChange({ ...item, value: e.target.value })
                          }
                        />
                      ) : null}
                      {item.field_type === 'date' && !item.options?.length
                        ? [
                            <FilterNumber key={`${item.internal_name}-from`}>
                              <Typography variant='body1'>
                                {localize.general.from}
                              </Typography>
                              <DateTimePicker
                                value={item.value ? item.value[0] : ''}
                                onChange={(newValue: any) =>
                                  onValueChange({
                                    ...item,
                                    value: [
                                      newValue,
                                      item.value ? item.value[1] : null,
                                    ],
                                  })
                                }
                              />
                            </FilterNumber>,
                            <FilterNumber key={`${item.internal_name}-to`}>
                              <Typography variant='body1'>
                                {localize.general.to}
                              </Typography>
                              <DateTimePicker
                                value={item.value ? item.value[1] : ''}
                                onChange={(newValue: any) =>
                                  onValueChange({
                                    ...item,
                                    value: [
                                      item.value ? item.value[0] : null,
                                      newValue,
                                    ],
                                  })
                                }
                              />
                            </FilterNumber>,
                          ]
                        : null}
                      {item.field_type === 'boolean' ? (
                        <FormGroup sx={{ flexDirection: 'row', gap: '10px' }}>
                          {[true, false].map((option) => (
                            <FormControlLabel
                              key={`${item.id}-${option ? 'true' : 'false'}`}
                              control={
                                <Checkbox
                                  checked={
                                    item.value?.includes(option) || false
                                  }
                                  onChange={(e, checked) =>
                                    onValueChange({
                                      ...item,
                                      value: checked
                                        ? [...(item.value || []), option]
                                        : [
                                            ...(item.value?.filter(
                                              (el: boolean) => el !== option
                                            ) || []),
                                          ],
                                    })
                                  }
                                  name={`${item.id}-${option ? 'true' : 'false'}`}
                                />
                              }
                              label={
                                localize.general[option ? 'true' : 'false']
                              }
                            />
                          ))}
                        </FormGroup>
                      ) : null}
                    </FilterItemDetails>
                  }
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default Filter;

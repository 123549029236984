import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { fieldsForTypes, templatesForTypes } from '../../utils/template';
import { preparedRequestBody } from '../../utils/form';
import { uploadBlobToAws } from '../../utils/aws';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CommonContext } from '../../contexts/CommonContext';
import { ProductApiClient } from '../../api/ProductApiClient';
import { ProjectUploadApiClient } from '../../api/ProjectUploadApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import ProductForm from '../../components/ProductForm/ProductForm';
import Page from '../../components/Page/Page';

export const ProductCreatePage: FC = () => {
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const [subItemFields, setSubItemFields] = useState<AnyObject[]>([]);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject, templates } = useContext(CommonContext);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const template = useMemo(
    () => templatesForTypes(templates, ['Product'])[0],
    [templates]
  );

  const onSubmit = async (mainItem: AnyObject, subItems: AnyObject[]) => {
    try {
      setSubmitting(true);
      const filesInternalNames = template?.custom_fields
        ?.filter((el: any) => el?.field_type === 'files')
        ?.map((el: any) => el?.internal_name) as string[];

      const mainItemData = Object.keys(mainItem).reduce((result: any, key) => {
        if (!filesInternalNames.includes(key)) {
          result[key] = mainItem[key];
        }
        return result;
      }, {});
      const createdProduct = await ProductApiClient.create(currentProject.id, {
        product: preparedRequestBody({
          mainItem: mainItemData,
          mainFields,
          subItems,
          subItemFields,
          subItemsKey: 'product_modifications_attributes',
        }),
        template_id: template?.id,
      });

      const uploadedFiles = await Promise.all(
        filesInternalNames
          .filter((intName) => mainItem[intName] && mainItem[intName].length)
          .map((intName: string) =>
            uploadBlobToAws({
              projectId: currentProject.id,
              mainItemId: createdProduct.id,
              additionalParams: `product_id=${createdProduct.id}&field_name=${intName}`,
              ApiClient: ProjectUploadApiClient,
              files: mainItem[intName],
            }).then((response) => {
              return { response, intName };
            })
          )
      );

      const filesFields: any = {};
      uploadedFiles.map(
        (res) =>
          (filesFields[res.intName] = [
            ...res.response.errors.filter((el) => !!el),
            ...res.response.success,
          ])
      );

      if (Object.values(filesFields).filter((v: any) => v?.length)?.length) {
        await ProductApiClient.update(
          currentProject.id,
          {
            product: {
              additional_fields: {
                ...createdProduct.additional_fields,
                ...filesFields,
              },
            },
            template_id: template?.id,
          },
          createdProduct.id
        );
        if (uploadedFiles.filter((v) => v.response.errors.length).length > 0) {
          setNotification({
            severity: 'warning',
            message: localize.general.warningCreateMessage,
          });
        } else {
          setNotification({
            severity: 'success',
            message: localize.general.successCreateMessage,
          });
        }
      } else {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
      }

      setSubmitting(false);
      navigate(`/cp/products/${createdProduct.id}`);
    } catch (er) {
      console.log(er);
    }
  };

  useEffect(() => {
    setMainFields([
      ...fieldsForTypes(templates, ['Product']).filter(
        (el: AnyObject) => el.editable
      ),
    ]);
    setSubItemFields([
      ...fieldsForTypes(templates, ['Product modification']).filter(
        (el: AnyObject) => el.editable
      ),
    ]);
  }, [templates, currentProject]);

  return (
    <Page title={localize.products.create.title} actions={[]}>
      <ProductForm
        mainFields={mainFields}
        subItemFields={subItemFields}
        submitting={submitting}
        addModificationText={localize.products.form.addModification}
        onSubmit={onSubmit}
      />
    </Page>
  );
};

export default ProductCreatePage;
